import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, TEALS, BLUES },
} = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const rowStyles = {
  boxShadow: 'none',
};

export const containerStyles = {
  padding: SPACING[24],
};

export const columnStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: 0,
};

export const headerStyles = {
  ...COPILOT_TYPOGRAPHY.HEADING.H4,
  color: NEUTRALS.N800_MATTER,
  marginBottom: SPACING[4],
};

export const subHeaderStyles = {
  ...COPILOT_TYPOGRAPHY.BODY.SMALL,
  color: NEUTRALS.N800_MATTER,
};

export const stratTypeContainerStyles = {
  marginTop: SPACING[24],
  marginBottom: SPACING[24],
  padding: 0,
};

export const cardHeaderStyles = {
  ...COPILOT_TYPOGRAPHY.HEADING.H5,
};

export const cardIconStyles = {
  fontWeight: 400,
  fontSize: 10,
  color: NEUTRALS.N900_BLACK,
  border: `2px solid ${NEUTRALS.N900_BLACK}`,
};

export const cardIconSelected = {
  color: BLUES.B500_WAVE,
  border: `2px solid ${BLUES.B500_WAVE}`,
};

export const cardTextStyle = {
  ...COPILOT_TYPOGRAPHY.DESCRIPTION.BOLD,
  marginBottom: SPACING[8],
  color: NEUTRALS.N400_GRANITE,
};

export const cardDescriptionStyle = {
  ...COPILOT_TYPOGRAPHY.BODY.SMALL,
  color: NEUTRALS.N400_GRANITE,
  marginTop: SPACING[8],
};

export const legendBadgeStyle = {
  ...COPILOT_TYPOGRAPHY.DESCRIPTION.BOLD,
  color: TEALS.T500_KEPPEL,
  border: `2px solid ${TEALS.T500_KEPPEL}`,
  borderRadius: 4,
  padding: `${SPACING[2]}px ${SPACING[16]}px`,
};

export const legendTextStyle = {
  ...COPILOT_TYPOGRAPHY.BODY.SMALL,
  color: NEUTRALS.N600_ABBEY,
};
