import React from 'react';
import { getChildDisplayNameByDsp } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { BasicStratInfoList } from './ConfirmHeader';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';

type Props = {
  dsp: number
  intelligentChildObjects?: boolean,
};

const BudgetOptimization = ({ intelligentChildObjects, dsp }: Props) => {
  const displayName = getChildDisplayNameByDsp(dsp);
  return (
    <>
      <BasicStratInfoList width={3} first={`Intelligent ${displayName}`} style={STRATEGY_CONFIRMATION_STYLES.subHeader} />
      <BasicStratInfoList width={9} first={intelligentChildObjects ? 'ON' : 'OFF'} style={STRATEGY_CONFIRMATION_STYLES.mainText} />
    </>
  );
};

export default BudgetOptimization;
