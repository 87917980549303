/* eslint-disable react/jsx-no-target-blank */
import _ from 'lodash';
import React, { Component, ReactNode, CSSProperties } from 'react';
import Markdown from 'react-markdown-renderer';
import { TOOLTIPS } from 'constantsBase';
import { Popup } from 'buildingBlocks';
import { toSlug } from 'utils/formattingUtils';
import { linkStyle } from './styles';

type Props = {
  content?: string | ReactNode,
  group?: string,
  name?: string,
  tooltipstyling?: CSSProperties,
};

const customGroupToWikiMap = {
  Helios: 'Helios',
  'Helios TTD': 'Helios',
};

const createTooltipData = () => (
  _.mapValues(TOOLTIPS, ({ link, content }: { link: string, content: string }) => ({
    content,
    link: `https://copilotsupport.freshdesk.com/support/solutions/articles/${link}`,
  }))
);

export class TooltipComponent extends Component<Props> {
  getWikiName = (group) => _.get(customGroupToWikiMap, group, group);

  getTipKey = () => {
    if (!this.props.name) {
      return undefined;
    }
    const slugName = toSlug(this.props.name);
    return (
      this.props.group
        ? toSlug(`${this.getWikiName(this.props.group)}_${slugName}`)
        : slugName
    );
  };

  renderContent = (key) => {
    const tooltips = createTooltipData();
    if (key && tooltips[key]) {
      const { content, link } = tooltips[key];

      // We open links in new tabs when they are links to our own sites
      return (
        <Popup.Content>
          <Markdown markdown={content} />
          { link
          && (
          <div>
            <a
              href={link}
              style={linkStyle}
              target="_blank"
            >
              Read More
            </a>
          </div>
          )}
        </Popup.Content>
      );
    }
    return (<Popup.Content>{this.props.content}</Popup.Content>);
  };

  render() {
    const key = this.getTipKey();

    return (
      <Popup
        trigger={(
          <i
            id={key}
            style={{ fontSize: '20px', ...this.props.tooltipstyling }}
            className="material-icons"
          >
            help_outline
          </i>
        )}
        position="right center"
        hoverable
      >
        {this.renderContent(key)}
      </Popup>
    );
  }
}

export default TooltipComponent;
