import { CSSProperties } from 'react';

export const linkStyle: CSSProperties = {
  textTransform: 'uppercase',
  marginTop: '1em',
  display: 'block',
  fontSize: '0.8em',
};

export default linkStyle;
