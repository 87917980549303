import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import numeral from 'numeral';
import { FLIGHT_EXTERNAL_TYPE, APP_NAME, DSP, STRATEGY_TYPE, AWGDimensions, GOAL_TYPES } from 'constantsBase';
import useFetcher, { PossibleStates, State } from 'utils/hooks/useFetcher';
import { getGoalTypeDB, transformBudgetSettingsDB, transformStrategyGoals } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import {
  OPTIMIZATION_LEVELS,
  ExternalTypeOptimizationLevel,
  HIGHER_ORDER_EXTERNAL_TYPE_IDS,
  EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL,
  OptimizationType,
} from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FAILURE_TEXT, Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData, BudgetAllocationResponse } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { getValidChildData } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { Flight, User, Member, StrategyType, OptimizationLevelType } from 'utils/types';
import { dateTimeFormatter } from 'utils/dateTime';
import { Permission, checkPermissions } from 'utils/featureFlags';
import {
  getDeepLinkForAPNCampaign,
  getDeepLinkForAPNLI,
  getDeepLinkForAPNIO,
  generateMessages,
  transformKeyOfObjWithFn,
} from 'utils/functionHelpers';
import { DBMLineItem, Microservices } from 'utils/copilotAPI';
import { toCamelCase } from 'utils/formattingUtils';
import { QSParams, StrategyWizardResponse, StrategyWizardStates } from './hooks';
import { BudgetSetting, FormulaType, GoalDB, RevenueTypeConfig, StrategyConfigurationStep, WizardFormForCreateStrategy, WizardFormGoalSelection, WizardFormValues } from './types';
import {
  stratTypesWithReportingOnlyTxt,
  UPDATE_BUDGET_ALLOCATION_STATE,
  BUDGET_OPTIMIZATION_MICRO_SERVICE,
  WizardSteps,
  INITIAL_WIZARD_VALUES_FOR_UI,
  ALL_OPERATORS,
  ALL_METRICS,
  CUSTOM_GOAL_FIELDS,
  TRUE_VIEWS,
  NCS_GOALS,
  METRIC_SEARCH_OBJS,
} from './constants';
import { hasSingleOutcomeAndValue, isAWGGoalType } from './steps/GoalSelection/utils';
import { RevenueTypeOutcomeOptions } from './steps/GoalSelection/constants';

const crossPlatformStratId = STRATEGY_TYPE.crossPlatformOptimization.id;

type FlightRun = {
  updatedAt: string
  status: string
};

export const isHigherOrderExternalType = (externalTypeId: number) => HIGHER_ORDER_EXTERNAL_TYPE_IDS.has(externalTypeId);

export const hasMultipleExternalTypesAttached = (flights: Array<Flight>) => _.size(_.uniq(_.map(flights, 'externalType'))) > 1;

export const multipleParentFlightsCheck = (flights: Flight[]) => {
  if (_.size(flights) <= 1) {
    return false;
  }
  // Multiple parent level external types must be a Cross-Platform Optimization strategy
  const parentLevelFlights = _.filter(flights, (flight: Flight) => isHigherOrderExternalType(flight.externalType));
  return _.size(parentLevelFlights) > 1;
};

export const flightKey = (f: Flight) => [f.externalType, f.externalId].join('_');
export const flightComparator = (f: Flight, f2: Flight) => _.isEqual(flightKey(f), flightKey(f2));

export const MODEL_TYPE = {
  Campaign: { text: 'Campaign', value: 'Campaign' },
  lineItem: { text: 'Line Item', value: 'LI' },
  insertionOrder: { text: 'Insertion Order', value: 'IO' },
};

export const MAX_NAME_LENGTH = 225;

const consoleBaseUrlTTD = 'https://desk.thetradedesk.com';
const consoleBaseUrlWMT = 'https://desk.dsp.walmart.com';

const getDeepLinkForTTDCampaign = (campId: string) => `${consoleBaseUrlTTD}/Campaigns/Detail/${campId}`;
const getDeepLinkForWMTCampaign = (campId: string) => `${consoleBaseUrlWMT}/Campaigns/Detail/${campId}`;
const getDeepLinkForTTDLI = (campId: string, adgroupId: string) => `${consoleBaseUrlTTD}/AdGroups/Detail/${adgroupId}?campaign=${campId}`;
const getDeepLinkForWMTLI = (campId: string, adgroupId: string) => `${consoleBaseUrlWMT}/AdGroups/Detail/${adgroupId}?campaign=${campId}`;

export const getDisplayName = (externalType: number) => {
  const fet = FLIGHT_EXTERNAL_TYPE.getById(externalType);
  if (fet) {
    return fet.displayName;
  }
  return '';
};

const getTTDMessage = (flight: Flight) => {
  const { parentName, parentExternalId, name, externalId, externalType } = flight;
  return [
    // eslint-disable-next-line max-len
    {
      type: 'Campaign',
      nameDetail: parentName,
      extId: parentExternalId,
      link: getDeepLinkForTTDCampaign(parentExternalId),
    },
    {
      type: getDisplayName(externalType),
      nameDetail: name,
      extId: externalId,
      link: getDeepLinkForTTDLI(parentExternalId, externalId),
    },
  ];
};

const getWMTMessage = (flight: Flight) => {
  const { parentName, parentExternalId, name, externalId, externalType } = flight;
  return [
    // eslint-disable-next-line max-len
    {
      type: 'Campaign',
      nameDetail: parentName,
      extId: parentExternalId,
      link: getDeepLinkForWMTCampaign(parentExternalId),
    },
    {
      type: getDisplayName(externalType),
      nameDetail: name,
      extId: externalId,
      link: getDeepLinkForWMTLI(parentExternalId, externalId),
    },
  ];
};

const consoleBaseUrlDBM = 'https://displayvideo.google.com/#ng_nav';
const consoleBaseUrlAMZN = 'https://advertising.amazon.com';

const getDeepLinkForDBMIOByLI = (flight: Flight, advExtId: string, memberExtId: string) => `${consoleBaseUrlDBM}/p/${memberExtId}/a/${advExtId}/c/${flight.grandParentExternalId}/io/${flight.parentExternalId}/lis`;
const getDeepLinkForDBMIOByIO = (flight: Flight, advExtId: string, memberExtId: string) => `${consoleBaseUrlDBM}/p/${memberExtId}/a/${advExtId}/c/${flight.parentExternalId}/io/${flight.externalId}/lis`;
const getDeepLinkForDBMLI = (flight: Flight, advExtId: string, memberExtId: string) => `${consoleBaseUrlDBM}/p/${memberExtId}/a/${advExtId}/c/${flight.grandParentExternalId}/io/${flight.parentExternalId}/li/${flight.externalId}/details`;
const getDeepLinkForAMZNOR = (flight: Flight, memberExtId: string) => `${consoleBaseUrlAMZN}/dsp/${memberExtId}/orders/${flight.externalId}/line-items`;

export const getFlightPopupMessage = (flight: Flight, advExtId: string, memberExtId: string, flightRun?: FlightRun) => {
  const { grandParentName, grandParentExternalId: gpExtId, parentName, parentExternalId, name, externalId } = flight;
  const map = {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [
      {
        type: 'IO',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForAPNIO(externalId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.apnCampaign.id]: [
      {
        type: 'IO',
        nameDetail: grandParentName,
        extId: gpExtId,
        link: getDeepLinkForAPNIO(gpExtId),
      },
      {
        type: 'LI',
        nameDetail: parentName,
        extId: parentExternalId,
        link: getDeepLinkForAPNLI(parentExternalId),
      },
      {
        type: 'Campaign',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForAPNCampaign(externalId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.apnLineItem.id]: [
      {
        type: 'IO',
        nameDetail: parentName,
        extId: parentExternalId,
        link: getDeepLinkForAPNIO(parentExternalId),
      },
      {
        type: 'LI',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForAPNLI(externalId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [
      {
        type: 'Campaign',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForTTDCampaign(externalId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.ttdMegagon.id]: getTTDMessage(flight),
    [FLIGHT_EXTERNAL_TYPE.wmtCampaign.id]: [
      {
        type: 'Campaign',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForWMTCampaign(externalId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.wmtMegagon.id]: getWMTMessage(flight),
    [FLIGHT_EXTERNAL_TYPE.dbmLineItem.id]: [
      {
        type: 'IO',
        nameDetail: parentName,
        extId: parentExternalId,
        link: getDeepLinkForDBMIOByLI(flight, advExtId, memberExtId),
      },
      {
        type: 'LI',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForDBMLI(flight, advExtId, memberExtId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [
      {
        type: 'IO',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForDBMIOByIO(flight, advExtId, memberExtId),
      },
    ],
    [FLIGHT_EXTERNAL_TYPE.amznOrder.id]: [
      {
        type: 'Order',
        nameDetail: name,
        extId: externalId,
        link: getDeepLinkForAMZNOR(flight, memberExtId),
      },
    ],
  };

  const messages = generateMessages(map[flight.externalType]);

  if (flightRun && !_.isUndefined(flightRun)) {
    const flightRunMsg = `${APP_NAME} last ran strategy on ${dateTimeFormatter.dateTime(flightRun.updatedAt)} \
- Status: ${flightRun.status}`;
    messages.push(`${flightRunMsg}`);
  }
  return messages;
};

export const createStrategy = (
  wizardForm: WizardFormForCreateStrategy,
  configToSave: {},
): { [key: string]: any } => {
  const {
    goalSelectionStep,
    strategyTypeSelectionStep,
    strategyConfigurationStep,
  } = wizardForm;
  const customGoal = _.get(goalSelectionStep, 'customGoal');
  const metricsConfig = _.get(goalSelectionStep, 'metricsConfig');
  const strategyGoals = transformStrategyGoals(strategyConfigurationStep, goalSelectionStep, strategyTypeSelectionStep.strategyType.id);
  const revTypeConfig = _.get(goalSelectionStep, 'budget');
  // strip prefix 'externalId-' from keys
  const formattedRevTypeConfig = _.mapKeys(revTypeConfig, (_v, k) => _.last(_.split(k, '-')));
  const hasRevType = !!_.size(revTypeConfig);

  const cleanedWizardFormValues = _.omit(wizardForm, [
    'budgetAllocationState',
    WizardSteps.attachFlightsStep,
    WizardSteps.goalSelectionStep,
    WizardSteps.strategyTypeSelectionStep,
    WizardSteps.strategyConfigurationStep,
    WizardSteps.strategyConfirmationStep,
  ]);

  // default mem & adv to null in case multiple mems/advs for cross-platform strategies
  const memberId = _.get(wizardForm.attachFlightsStep, 'member.id', null);
  const advertiserId = _.get(wizardForm.attachFlightsStep, 'advertiser.id', null);
  // default brand to null if there is no brand
  const brandId = _.get(wizardForm.attachFlightsStep, 'brand.id', null);
  const strategyToSave = {
    ...cleanedWizardFormValues,
    strategyGoals,
    strategyType: wizardForm.strategyTypeSelectionStep.strategyType.id,
    config: configToSave,
    name: wizardForm.strategyConfirmationStep.name,
    member: memberId,
    advertiser: advertiserId,
    brand: brandId,
    ...(hasRevType && { revTypeConfig: formattedRevTypeConfig }),
    ...(_.size(customGoal) && { customGoalMetadata: { metricsConfig, customGoal } }),
  };

  const additionalAlgos = _.get(strategyConfigurationStep, 'additionalAlgos');
  // additionalAlgos is used by admins to activate the MAB
  return additionalAlgos
    ? { ...strategyToSave, additionalAlgos }
    : strategyToSave;
};

export const getStepUrl = (strategyId: number | undefined, step: number) => {
  const linkId = _.isUndefined(strategyId) ? '' : `/${strategyId}`;
  return `/strategies/wizard${linkId}/${step}`;
};

export const canManageFlights = (user: User, member: Member) => checkPermissions(user, Permission.manageStrategyFlights, member);

export const canManageBrands = (user: User) => checkPermissions(user, Permission.manageBrands);

export const REQUIRED_QS_KEYS = ['advertiser', 'flightExtIds'];

export const shouldInitFromQueryString = (qsParams: QSParams, requiredKeys: Array<string>) => _.every(requiredKeys, _.partial(_.has, qsParams));

export const getDspNameFromExtTypeId = (externalTypeId: number) => {
  switch (externalTypeId) {
    case FLIGHT_EXTERNAL_TYPE.apnLineItem.id:
    case FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id:
      return DSP.APN.displayName;
    case FLIGHT_EXTERNAL_TYPE.ttdMegagon.id:
    case FLIGHT_EXTERNAL_TYPE.ttdCampaign.id:
      return DSP.TTD.displayName;
    case FLIGHT_EXTERNAL_TYPE.dbmLineItem.id:
    case FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id:
      return DSP.DBM.displayName;
    case FLIGHT_EXTERNAL_TYPE.amznLineItem.id:
    case FLIGHT_EXTERNAL_TYPE.amznOrder.id:
      return DSP.AMZN.displayName;
    default:
      console.error('missing display name mapping');
      return null;
  }
};

export const buildFlightName = (flight: Flight) => {
  const flightName = flight.name;
  return (_.size(flightName) > MAX_NAME_LENGTH) ? flightName.slice(0, MAX_NAME_LENGTH) : flightName;
};

export const getOptimizationLevelKey = (dsp: number, oL: OptimizationLevelType) => _.findKey(
  OPTIMIZATION_LEVELS[dsp as number],
  (o: OptimizationLevelType) => o.name === _.get(oL, 'name', ExternalTypeOptimizationLevel.HIGHER_ORDER),
);

export const getUniqFlightExtIds = (attachedFlights: Array<Flight>) => _.uniq(_.map(attachedFlights, (flight: Flight) => flight.externalType));

export const getOptimizationLevelForStrat = (attachedFlights: Array<Flight>, strategyType: StrategyType) => {
  const stratTypeId = _.get(strategyType, 'id');
  if (strategyType && (stratTypeId !== crossPlatformStratId)) {
    const stratExtTypeId = _.get(_.head(strategyType.flightExternalTypes), 'id');
    return EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[stratExtTypeId].optimizationLevel;
  }
  if (strategyType && (stratTypeId === crossPlatformStratId)) {
    return OPTIMIZATION_LEVELS[DSP.MULTIPLE.id][ExternalTypeOptimizationLevel.HIGHER_ORDER];
  }
  const attachedFlightsExtTypeIds = getUniqFlightExtIds(attachedFlights);
  return _.size(attachedFlightsExtTypeIds) > 1
    ? OPTIMIZATION_LEVELS[DSP.MULTIPLE.id][ExternalTypeOptimizationLevel.HIGHER_ORDER]
    : _.get(EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[_.head(attachedFlightsExtTypeIds)], 'optimizationLevel');
};

export const getSelectedOptType = (strategyType: StrategyType) => {
  const stratTypeId = _.get(strategyType, 'id');
  if (!stratTypeId) {
    return null;
  }
  if (stratTypeId === crossPlatformStratId) {
    return OptimizationType.crossPlatform;
  }
  if (isHigherOrderExternalType(_.head(strategyType.flightExternalTypes).id)) {
    return OptimizationType.campaign;
  }
  return OptimizationType.lineItem;
};

export const getOptLevelByFlightExtType = (dsp: number, flightExtType: number) => {
  const eligibleOptLevels = OPTIMIZATION_LEVELS[dsp];
  // eslint-disable-next-line eqeqeq
  return _.find(eligibleOptLevels, (v) => _.includes(v.externalTypeIds, flightExtType));
};

export const getSyncExternalTypeByDSP = (dsp: number): number => {
  switch (dsp) {
    case DSP.APN.id:
      return FLIGHT_EXTERNAL_TYPE.apnLineItem.id;
    case DSP.TTD.id:
      return FLIGHT_EXTERNAL_TYPE.ttdMegagon.id;
    case DSP.AMZN.id:
      return FLIGHT_EXTERNAL_TYPE.amznLineItem.id;
    case DSP.DBM.id:
      return FLIGHT_EXTERNAL_TYPE.dbmLineItem.id;
    case DSP.WALMART.id:
      return FLIGHT_EXTERNAL_TYPE.wmtMegagon.id;
    default:
      console.error('missing child level external type mapping');
      return null;
  }
};

export const getParentExternalTypeByDSP = (dsp: number): number => {
  switch (dsp) {
    case DSP.APN.id:
      return FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id;
    case DSP.TTD.id:
      return FLIGHT_EXTERNAL_TYPE.ttdCampaign.id;
    case DSP.AMZN.id:
      return FLIGHT_EXTERNAL_TYPE.amznOrder.id;
    case DSP.DBM.id:
      return FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id;
    case DSP.WALMART.id:
      return FLIGHT_EXTERNAL_TYPE.wmtCampaign.id;
    default:
      console.error('missing parent level external type mapping');
      return null;
  }
};

export const getDspByHigherOrderExtType = (extType: number): number => {
  switch (extType) {
    case FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id:
      return DSP.APN.id;
    case FLIGHT_EXTERNAL_TYPE.ttdCampaign.id:
      return DSP.TTD.id;
    case FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id:
      return DSP.DBM.id;
    case FLIGHT_EXTERNAL_TYPE.amznOrder.id:
      return DSP.AMZN.id;
    case FLIGHT_EXTERNAL_TYPE.wmtCampaign.id:
      return DSP.WALMART.id;
    default:
      console.error('missing extType to DSP mapping');
      return null;
  }
};

export const getDspByMemberOrFlights = (attachedFlights: Array<Flight>, member?: Member) => {
  if (member) {
    return member.dsp;
  }
  return hasMultipleExternalTypesAttached(attachedFlights) ? DSP.MULTIPLE.id : _.get(EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[_.get(_.head(attachedFlights), 'externalType')], 'dsp', DSP.MULTIPLE.id);
};

export const parentExtTypeByExtType = {
  [FLIGHT_EXTERNAL_TYPE.apnLineItem.id]: FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id,
  [FLIGHT_EXTERNAL_TYPE.ttdMegagon.id]: FLIGHT_EXTERNAL_TYPE.ttdCampaign.id,
  [FLIGHT_EXTERNAL_TYPE.wmtMegagon.id]: FLIGHT_EXTERNAL_TYPE.wmtCampaign.id,
  [FLIGHT_EXTERNAL_TYPE.dbmLineItem.id]: FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id,
  [FLIGHT_EXTERNAL_TYPE.amznLineItem.id]: FLIGHT_EXTERNAL_TYPE.amznOrder.id,
};

export const getReportOnlyTxt = (strategyTypeId: number, hasReportingText: boolean = true) => {
  if (!hasReportingText) {
    return '';
  }
  return _.includes(stratTypesWithReportingOnlyTxt, strategyTypeId) ? ' (For reporting only)' : '';
};

export const getDspFromExtTypeId = (externalTypeId: number) => {
  switch (externalTypeId) {
    case FLIGHT_EXTERNAL_TYPE.apnLineItem.id:
    case FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id:
      return DSP.APN.id;
    case FLIGHT_EXTERNAL_TYPE.ttdMegagon.id:
    case FLIGHT_EXTERNAL_TYPE.ttdCampaign.id:
      return DSP.TTD.id;
    case FLIGHT_EXTERNAL_TYPE.dbmLineItem.id:
    case FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id:
      return DSP.DBM.id;
    case FLIGHT_EXTERNAL_TYPE.amznLineItem.id:
    case FLIGHT_EXTERNAL_TYPE.amznOrder.id:
      return DSP.AMZN.id;
    case FLIGHT_EXTERNAL_TYPE.wmtCampaign.id:
    case FLIGHT_EXTERNAL_TYPE.wmtMegagon.id:
      return DSP.WALMART.id;
    default:
      console.error('missing externalTypeId to DSP mapping');
      return null;
  }
};

const getRevenueOutcomeType = (revTypeConfig) => (hasSingleOutcomeAndValue(revTypeConfig) ? RevenueTypeOutcomeOptions.single : RevenueTypeOutcomeOptions.multiple);

export const fetchBudgetAllocationState = (
  flightsSelected: Array<Flight>,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData | string },
  dispatch: Dispatch<SetStateAction<any>>,
  stratTypeId?: number,
) => {
  const flightsToFetchFor = _.filter(flightsSelected, (f: Flight) => !_.includes(_.keys(budgetAllocationData), f.externalId) || budgetAllocationData[f.externalId] === FAILURE_TEXT);
  const newBudgetAllocationData = { ...budgetAllocationData };
  const getFlightsBudgetAllocData = async (flight: Flight) => {
    const flightExtId = flight.externalId;
    try {
      const hierachy = await Microservices.runService(
        {
          member_int_id: flight.member,
          member_ext_id: flight.memExtId,
          adv_ext_id: flight.advExtId,
          flight_ext_id: flightExtId,
          flight_ext_type: flight.externalType,
          dsp: flight.dsp,
          ...(stratTypeId && { strat_type_id: stratTypeId }),
        },
        BUDGET_OPTIMIZATION_MICRO_SERVICE,
      );
      const transformedData = transformKeyOfObjWithFn(hierachy.data, toCamelCase);
      const { hierarchy } = transformedData;
      const { validData, invalidData } = getValidChildData(hierarchy.parentSettings, hierarchy.childExtIdToSettings);
      const updatedHierarchy = {
        ...hierarchy,
        childExtIdToSettings: validData,
        invalidData,
      };
      newBudgetAllocationData[flightExtId] = { ...transformedData, hierarchy: updatedHierarchy };
    } catch (error) {
      newBudgetAllocationData[flightExtId] = FAILURE_TEXT;
    }
  };

  const getBudgetAllocationData = async () => {
    await dispatch({
      type: UPDATE_BUDGET_ALLOCATION_STATE,
      payload: { kind: Status.loading },
    });

    const actions = _.map(flightsToFetchFor, getFlightsBudgetAllocData);
    const results = Promise.all(actions);
    // wait for all the fetches to complete before dispatching the updated budgetAllocationState
    results.then(() => {
      const hasOnlyErrors = _.size(_.filter(_.values(newBudgetAllocationData), (budgetAllocData) => _.isEqual(budgetAllocData, FAILURE_TEXT))) === _.size(flightsSelected);
      const payload = hasOnlyErrors ? { kind: Status.error, error: FAILURE_TEXT } : { kind: Status.hasData, data: newBudgetAllocationData };
      dispatch({ type: UPDATE_BUDGET_ALLOCATION_STATE, payload });
    });
  };
  getBudgetAllocationData();
};

export const useFetchDspSpecificChildren = (dsp: number, budgetAllocationData: BudgetAllocationResponse, firstFlightExtId: string) => {
  const fetchDspSpecificChildren = async (): Promise<State> => {
    switch (budgetAllocationData.kind) {
      case Status.hasData: {
        if (dsp !== DSP.DBM.id) {
          return { kind: PossibleStates.hasData, data: [] };
        }
        const childrenExtIds = _.keys(budgetAllocationData.data[firstFlightExtId].hierarchy.childExtIdToSettings);
        const { data: lineItems } = await DBMLineItem.get({
          where: { externalId: childrenExtIds },
          limit: 200,
        });
        if (_.isNil(lineItems)) {
          return {
            kind: PossibleStates.error,
            errorObject: { message: `received ${lineItems} for children from API` },
          };
        }
        return { kind: PossibleStates.hasData, data: lineItems };
      }
      case Status.error: {
        return { kind: PossibleStates.error, errorObject: { message: budgetAllocationData.error } };
      }
      default:
        return { kind: PossibleStates[budgetAllocationData.kind] };
    }
  };
  return useFetcher(fetchDspSpecificChildren, [dsp, budgetAllocationData]);
};

export const getInitialWizardFormFieldsUIValues = (strategyState: StrategyWizardResponse) => {
  switch (strategyState.kind) {
    case PossibleStates.hasData:
      // eslint-disable-next-line no-case-declarations
      const initialFormValues: Partial<WizardFormValues> = {
        attachFlightsStep: {
          ...INITIAL_WIZARD_VALUES_FOR_UI.attachFlightsStep,
          brand: strategyState.data.brand,
          member: strategyState.data.member,
          advertiser: strategyState.data.advertiser,
          optimizationLevel: strategyState.data.optimizationLevel,
          attachedFlights: strategyState.data.attachedFlights,
          defaultCurrency: strategyState.data.defaultCurrency,
          selectedOptType: _.get(strategyState.data, 'selectedOptType'),
        },
        goalSelectionStep: {
          goal: {
            target: _.get(_.head(strategyState.data.strategyGoals), 'target'),
            type: getGoalTypeDB(strategyState.data),
            impValueFilters: _.get(strategyState.data.config, 'impValueFilters'),
          },
          budget: strategyState.data.revTypeConfig,
          budgetSettings: _.sortBy(transformBudgetSettingsDB(strategyState.data.config.budgetSettings) as Array<BudgetSetting>, ['startDate']),
          revenueOutcomeType: strategyState.data.revTypeConfig ? getRevenueOutcomeType(strategyState.data.revTypeConfig) : null,
          customGoal: _.get(strategyState.data, 'customGoal'),
          metricsConfig: _.get(strategyState.data, 'metricsConfig'),
        },
        strategyTypeSelectionStep: {
          strategyType: strategyState.data.strategyType,
        },
        strategyConfigurationStep: { ..._.omit(strategyState.data.config, ['revTypeConfig', 'hasCustomRevenueType', 'strategyGoals']) } as StrategyConfigurationStep,
        strategyConfirmationStep: {
          name: strategyState.data.name,
        },
      };
      // eslint-disable-next-line no-case-declarations
      const initialValues = strategyState.data.member?.dsp === DSP.AMZN.id
        ? { ...initialFormValues, productLocation: strategyState.data?.productLocation }
        : { ...initialFormValues };

      return initialValues;
    case StrategyWizardStates.initFromQs:
      return strategyState.data;
    case StrategyWizardStates.newStrategy:
      return INITIAL_WIZARD_VALUES_FOR_UI;
    default:
      return null;
  }
};

export const isCrossPlatformStrategyType = (strategyTypeId: number | string) => (+strategyTypeId === crossPlatformStratId);

export const roundNum = (num: number, format: string) => numeral(num).format(format);

export const mapRevTypeConfigFromDbToUi = (revTypeConfig) => _(revTypeConfig)
  .keyBy('parentExtId')
  .mapValues(({ goal, revenueValue, externalType }) => ({
    outcome: goal.name,
    revenueValue,
    externalType,
  }))
  .mapKeys((_config, key) => `externalId-${key}`)
  .value();

export const getSingleRevTypeAndValue = (revTypeConfig: RevenueTypeConfig) => {
  const firstRevTypeObj = _.head(_.values(revTypeConfig));
  const revenueType = _.get(firstRevTypeObj, 'outcome', null);
  const clientEventRevenueValue = _.size(firstRevTypeObj) ? _.toNumber(_.get(firstRevTypeObj, 'revenueValue')) : null;
  return { revenueType, clientEventRevenueValue };
};

export const formatPixelGoalWeightsForUi = (goalWeights) => _(goalWeights)
  .groupBy('dsp')
  .mapValues((weights) => _.flatMap(weights, (weightObj) => _.map(weightObj.weight, (weight, id) => ({ id, weight }))))
  .value();

export const formatMetricsConfigFromDbToUi = (goalWeights) => _(goalWeights)
  .groupBy(({ metric }) => metric)
  .mapValues((metricConfig) => ({
    name: metricConfig[0].metric,
    dimension: metricConfig[0].dimension,
    isWeighted: true,
    weighting: metricConfig.reduce((acc, config) => {
      const weight = config.dimension === AWGDimensions.dsp ? config.weight[config.dsp] : config.weight;
      return { ...acc, [config.dsp]: weight };
    }, {}),
  }))
  .value();

export const checkIsOperator = (elem: string) => _.includes(ALL_OPERATORS, elem);
export const checkIsMetric = (elem: string) => _.includes(ALL_METRICS, elem);
export const checkIsNumber = (elem: string) => !isNaN(_.toNumber(elem));

const extractEquation = (equation: string) => {
  const formula = [];
  const isNumber = (char) => /^\d*\.?\d+$/.test(char);
  let subStr = '';

  _.forEach(equation, (char) => {
    if (checkIsOperator(char)) {
      if (subStr !== '' && (checkIsMetric(subStr) || isNumber(subStr))) {
        formula.push(subStr);
        subStr = '';
      }
      formula.push(char);
    } else {
      subStr += char;
    }
  });

  // Handle remaining string after loop
  if (subStr !== '' && (checkIsMetric(subStr) || isNumber(subStr))) {
    formula.push(subStr);
  }

  return {
    formula,
    metrics: _.filter(formula, (elem) => checkIsMetric(elem)),
  };
};

export const getTransformedEquation = (equation: string) => {
  const { formula } = extractEquation(equation);
  const formulaArray = _.map(formula, (item) => (
    checkIsOperator(item) ? item : _.startCase(item)
  ));
  const transformedEquation = formulaArray.join(' ');
  return transformedEquation;
};

const getGoalAcronym = (goalName: string) => {
  switch (goalName) {
    case ('weighted_cpm'):
      return 'wCPM';
    case ('emea_attention_score'):
      return 'ATT';
    case ('fmea'):
      return 'FMEA';
    case ('impact_outcome'):
      return GOAL_TYPES.impactOutcome.value;
    default:
      return _.replace(goalName, 'awg_', '');
  }
};

/**
 * converts a goal object from DB to an object of type FormulaType
 * @param dbGoalObj goal object from DB
 * @returns customGoal object
 */
export const formatGoalFromDbToUi = (dbGoalObj: Array<GoalDB>): FormulaType => {
  const goalObj = _.pick(_.head(dbGoalObj), CUSTOM_GOAL_FIELDS);
  return {
    ..._.omit(goalObj, ['displayName', 'equation', 'direction']),
    ...extractEquation(goalObj.equation),
    optimizationDirection: goalObj.direction,
    value: getGoalAcronym(goalObj.name),
    name: goalObj.displayName,
  };
};

export const isTrueView = (metric: string) => _.isEqual(_.toLower(metric), 'trueviews');

export const getDropzoneContent = (metric: string) => (isTrueView(metric) ? TRUE_VIEWS : _.get(_.find(METRIC_SEARCH_OBJS, ({ value }) => value === metric), 'text'));

export const hasExternalCustomValueMetric = (goalType: string, goalSelectionStep: WizardFormGoalSelection) => {
  const goalTypeEquation = isAWGGoalType(goalType) ? _.get(goalSelectionStep, 'customGoal.metrics') : _.get(GOAL_TYPES, `${goalType}.equation`);
  return _.includes(goalTypeEquation, 'externalCustomValue');
};

export const isNCSGoal = (goalType: string) => _.startsWith(goalType, 'ncs_nb_') || _.includes(NCS_GOALS, goalType);
