import _ from 'lodash';
import React from 'react';
import { Grid, Header, Icon, Table } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { BudgetGroup, BudgetInterval, GroupSettings } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { displayAllocationRange, displayDailyDelivery, displayLifetimeDelivery } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { displayCurrencyOrImps } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { BudgetSetting } from 'containers/StrategyWizard/types';
import { TRUNCATION_LIMIT } from 'constantsBase';
import { truncate } from 'utils/formattingUtils';
import { Currency } from 'utils/types';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import { displayDescription } from '../utils';
import InfoCircleIcon from './InfoCircleIcon';

const { column } = strategyWizardStyles;

type BudgetGroupRowProps = {
  group: BudgetGroup
  requiredDailyValue: number
  remainingSpendDays: number
  budgetMetric: string
};

const BudgetGroupRow = ({
  group: { groupName, min, max, aggDelivery },
  requiredDailyValue,
  remainingSpendDays,
  budgetMetric,
}: BudgetGroupRowProps) => (
  <Table.Row>
    <Table.Cell title={groupName} style={STRATEGY_CONFIRMATION_STYLES.groupName}>{truncate(groupName, TRUNCATION_LIMIT)}</Table.Cell>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.allocationRange}>{displayAllocationRange(min, max)}</Table.Cell>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.delivery}>{displayDailyDelivery(requiredDailyValue, min, max, budgetMetric)}</Table.Cell>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.delivery}>{displayLifetimeDelivery(requiredDailyValue, min, max, budgetMetric, remainingSpendDays, aggDelivery)}</Table.Cell>
  </Table.Row>
);

type BudgetManagementProps = {
  goToStep?: () => void
  groupSettings: GroupSettings
  defaultCurrency: Currency
  isCrossPlatformOptimization: boolean
  interval: BudgetInterval | BudgetSetting
  remainingSpendDays: number
  requiredDailyValue: number
};

const BudgetManagement = ({
  goToStep,
  groupSettings,
  defaultCurrency,
  isCrossPlatformOptimization,
  interval,
  remainingSpendDays,
  requiredDailyValue,
}: BudgetManagementProps) => {
  const groupSize = _.size(groupSettings);

  const budgetMetric = isCrossPlatformOptimization
    ? defaultCurrency.code
    : displayCurrencyOrImps((interval as BudgetInterval));

  return (
    <Grid.Row>
      <Grid.Column style={column}>
        <Header
          as="h4"
          style={STRATEGY_CONFIRMATION_STYLES.header}
        >
          Budget Management
          <span style={STRATEGY_CONFIRMATION_STYLES.icon}><Icon name="edit" size="small" link onClick={goToStep} /></span>
        </Header>
        <div style={STRATEGY_CONFIRMATION_STYLES.step5Table}>
          <InfoCircleIcon />
          {displayDescription(groupSize, 'Group')}
        </div>
        <Table style={STRATEGY_CONFIRMATION_STYLES.dspObjectTable}>
          <Table.Header>
            <Table.Row key={column.value}>
              <Table.HeaderCell style={STRATEGY_CONFIRMATION_STYLES.groupName}>
                Budget Group
              </Table.HeaderCell>
              <Table.HeaderCell style={STRATEGY_CONFIRMATION_STYLES.allocationRange}>
                Allocation Range
              </Table.HeaderCell>
              <Table.HeaderCell style={STRATEGY_CONFIRMATION_STYLES.delivery}>
                Daily Delivery
              </Table.HeaderCell>
              <Table.HeaderCell style={STRATEGY_CONFIRMATION_STYLES.delivery}>
                Lifetime Delivery
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(groupSettings, (group: BudgetGroup, groupKey: string) => (
              <BudgetGroupRow
                key={groupKey}
                group={group}
                requiredDailyValue={requiredDailyValue}
                remainingSpendDays={remainingSpendDays}
                budgetMetric={budgetMetric}
              />
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
};

export default BudgetManagement;
