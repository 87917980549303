import { CSSProperties } from 'react';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS },
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

export const STRATEGY_CONFIGURATION_STYLES: { [key: string]: CSSProperties } = {
  header: {
    ...COPILOT_TYPOGRAPHY.HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    marginBottom: SPACING[4],
  },
  subHeader: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N800_MATTER,
  },
  container: {
    padding: 0,
    position: 'relative',
    marginBottom: SPACING[32],
  },
};
