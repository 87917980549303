import _ from 'lodash';
import React from 'react';
import { Card } from 'buildingBlocks';
import { StrategyType } from 'utils/types';
import { isStrategyTypeDisabled } from 'utils/hooks/useStrategyTypeFetcher';
import { StrategyTypeStatus } from '../StrategyType';
import { DisabledStrategyTypeCard, EnabledStrategyTypeCard } from './StrategyTypeCard';
import { STRATEGY_TYPES_PER_ROW } from '../constants';

export type Props = {
  onChange: Function,
  value: StrategyType,
  items: Array<StrategyType>
  strategyTypesToDisable?: Array<StrategyTypeStatus>,
};

const StrategyTypeRadio = ({ items, onChange, value, strategyTypesToDisable }: Props) => {
  const onClick = (selected) => {
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <Card.Group itemsPerRow={STRATEGY_TYPES_PER_ROW}>
      {_.map(items, (item) => {
        const itemSelected = item.id === _.get(value, 'id');
        const restrictions = _.get(strategyTypesToDisable, item.id);
        const isDisabled = isStrategyTypeDisabled(restrictions);
        if (isDisabled) {
          return (
            <DisabledStrategyTypeCard
              key={item.id}
              item={item}
            />
          );
        }
        return (
          <EnabledStrategyTypeCard
            key={item.id}
            item={item}
            itemSelected={itemSelected}
            onClick={!itemSelected ? () => onClick(item) : undefined}
          />
        );
      })}
    </Card.Group>
  );
};

export default StrategyTypeRadio;
