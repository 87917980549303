import { CSSProperties } from 'react';
import { FONT_FAMILY } from 'constantsBase';
import { COPILOT_COLORS, COPILOT_TYPOGRAPHY, COPILOT_LAYOUT } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, REDS, BLUES },
} = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const USER_SETTINGS: { [key: string]: CSSProperties } = {
  baseTable: {
    background: 'white',
    fontFamily: FONT_FAMILY,
    width: '100%',
    margin: '0px',
    cursor: 'default',
  },
  baseRow: {
    boxShadow: 'none',
    borderBottom: `1px solid ${NEUTRALS.N200_LINEN}`,
  },
  baseColumn: {
    padding: 0,
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: '20px',
    color: NEUTRALS.N800_MATTER,
    lineHeight: '28px',
    padding: '14px 0px 0px 14px',
  },
  lineItemHeader: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: '16px',
    color: NEUTRALS.N600_ABBEY,
    width: '240px',
    lineHeight: '24px',
    padding: '14px 0px 0px 14px',
  },
  lineItemRow: {
    height: '15px',
  },
  memberStyle: {
    width: '479px',
    height: '32px',
    padding: '0px 0px 0px 14px',
    borderColor: NEUTRALS.N200_LINEN,
    verticalAlign: 'top',
  },
  emailsCellStyle: {
    width: '509px',
    height: '35px',
    padding: '0px 14px 0px 14px',
    verticalAlign: 'top',
  },
  textAreaEmails: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N400_GRANITE,
    fontFamily: 'Gilroy',
    width: '509px',
    height: '35px',
    padding: '0px 0px 0px 14px',
    borderColor: NEUTRALS.N200_LINEN,
  },
  memberEmailsButton: {
    fontFamily: FONT_FAMILY,
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
    padding: '5px 15px',
    height: 32,
    backgroundColor: 'white',
    border: '1px',
    borderStyle: 'solid',
    borderColor: NEUTRALS.N300_ALTO,
    borderRadius: '4px',
  },
  disabledButtonStyle: {
    color: NEUTRALS.N300_ALTO,
    cursor: 'default',
  },
  memberEmailsAdd: {
    fontSize: '14px',
    color: NEUTRALS.N800_MATTER,
    position: 'relative',
    paddingRight: '7px',
  },
  memberEmailsMark: {
    ...COPILOT_TYPOGRAPHY.BODY.MEDIUM,
    color: NEUTRALS.N800_MATTER,
    fontWeight: 400,
    fontSize: '26px',
    position: 'relative',
    paddingRight: '7px',
  },
  memberDropdownStyle: {
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    height: 36,
  },
  emailInput: {
    width: '509px',
    minHeight: '36px',
    height: 'auto',
    overflow: 'auto',
    flexWrap: 'wrap',
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  emailItem: {
    backgroundColor: NEUTRALS.N50_ALABASTER,
    color: NEUTRALS.N800_MATTER,
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
    borderRadius: '4px',
    height: '23px',
    padding: '0 4px 0 2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 4px 5px 6px',
  },
  enterEmails: {
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
    borderStyle: 'none',
    width: '250px',
    padding: '0px 0px 0px 7px',
    outline: 'none',
  },
  emailDelete: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: '0',
    margin: '3px 0 0 4px',
  },
  errorMessageStyle: {
    height: '20px',
    fontWeight: 400,
    fontSize: '12px',
    color: REDS.R700_VALENTINE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  saveUndoStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'end',
    verticalAlign: 'bottom',
    height: '220px',
    margin: '0 24px 24px 0',
  },
  saveButtonStyle: {
    fontFamily: FONT_FAMILY,
    height: SPACING[32],
    width: '100px',
    color: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: SPACING[4],
    borderColor: BLUES.B500_WAVE,
    backgroundColor: BLUES.B500_WAVE,
    marginLeft: SPACING[16],
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  disabledSaveButtonStyle: {
    backgroundColor: BLUES.B100_FISH,
    borderColor: BLUES.B100_FISH,
    borderStyle: 'none',
  },
  undoButtonStyle: {
    fontFamily: FONT_FAMILY,
    height: SPACING[32],
    width: '100px',
    border: '1px solid',
    borderRadius: SPACING[4],
    color: NEUTRALS.N800_MATTER,
    backgroundColor: NEUTRALS.N0_WHITE,
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'capitalize',
    textAlign: 'center',
    borderColor: NEUTRALS.N300_ALTO,
  },
  memberShareWithTitleStyle: {
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    fontSize: '14px',
    padding: '10px 0px 0px 14px',
    color: NEUTRALS.N600_ABBEY,
    width: '240px',
    lineHeight: '24px',
  },
  checkCircleDisabledStyle: {
    filter: 'invert(0%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
  },
  timeContainer: {
    display: 'flex',
    fontSize: '1em',
    paddingTop: 14,
  },
  frequencyTime: {
    margin: 'auto',
    marginRight: 16,
    textTransform: 'capitalize',
  },
  notifyMeInputs: {
    minWidth: '286px',
    height: SPACING[32],
    marginRight: 0,
  },
  editButtonStyle: {
    float: 'left',
    cursor: 'pointer',
    marginRight: '80rem',
  },
  removeIconStyle: {
    marginRight: '7px',
  },
  removeButtonStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
