import _ from 'lodash';
import React from 'react';
import { Table } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { AdvertiserMemberInfo } from 'containers/StrategyWizard/types';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { truncate } from 'utils/formattingUtils';
import { Flight } from 'utils/types';
import InfoCircleIcon from './InfoCircleIcon';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import { getFlightDescriptionAndColor } from '../utils';

const { column } = strategyWizardStyles;

type SingleDSPObjectTableProps = {
  flights: Array<Flight>
  advertiserMemberInfo: Array<AdvertiserMemberInfo>
  flightCategory: string
  dspCount?: number
};

const FlightTableHeader = () => (
  <Table.Header>
    <Table.Row key={column.value}>
      <Table.HeaderCell>Object Name</Table.HeaderCell>
      <Table.HeaderCell>DSP</Table.HeaderCell>
      <Table.HeaderCell>Member</Table.HeaderCell>
      <Table.HeaderCell>Advertiser</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

type FlightRowProps = {
  flight: Flight,
  advertiser: AdvertiserMemberInfo
};

const FlightRow = ({ flight, advertiser }: FlightRowProps) => (
  <Table.Row>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.objectName} title={flight.name}>{truncate(flight.name, 140)}</Table.Cell>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.dsp}><DspIcon dspId={flight.dsp} /></Table.Cell>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.member}>{advertiser.member.displayName}</Table.Cell>
    <Table.Cell style={STRATEGY_CONFIRMATION_STYLES.member}>{advertiser.name}</Table.Cell>
  </Table.Row>
);

const SingleDSPObjectTable = ({
  flights,
  advertiserMemberInfo,
  flightCategory,
  dspCount,
}: SingleDSPObjectTableProps) => {
  const {
    color: tableColor,
    desc: flightDesc,
  } = getFlightDescriptionAndColor(flights, flightCategory, dspCount);

  return (
    <>
      <div style={STRATEGY_CONFIRMATION_STYLES.step5Table}>
        <InfoCircleIcon />
        {flightDesc}
      </div>
      <Table style={{ ...STRATEGY_CONFIRMATION_STYLES.dspObjectTable, backgroundColor: tableColor }}>
        {(flightCategory === FlightCategory.attachedToThisStrategy) && (<FlightTableHeader />)}
        <Table.Body>
          {/* eslint-disable-next-line consistent-return */}
          {_.map(flights, (flight: Flight, i: number) => {
            const advertiser = _.head(_.filter(advertiserMemberInfo, ['id', flight.advertiser]));
            if (advertiser) {
              return (<FlightRow key={i + 1} flight={flight} advertiser={advertiser} />);
            }
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default SingleDSPObjectTable;
