import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import momentTimezone from 'moment-timezone';
import { ButtonProps, Grid, Popup, Button } from 'buildingBlocks';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { Mode } from 'containers/User/types';
import { GlobalState } from 'reducers';
import { isSystemAdmin } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { useMemberFetcher } from 'utils/hooks/useMemberFetcher';
import { buildKey } from 'utils/localstorage/utils';
import { MemberEmail, MemberEmailType, User, UserSettings as UserSettingType } from 'utils/types';
import DisplayTheme from './components/DisplayTheme';
import EmailNotifications from './components/EmailNotifications';
import MemberEmails from './components/MemberEmails';
import { PAGE_NAME, SHARED_NOTIFICATION_LINK } from './constants';
import { USER_SETTINGS } from './style';
import { updateUserSettings } from './utils';

const {
  baseTable,
  baseRow,
  baseColumn,
  sectionHeader,
  lineItemRow,
  memberEmailsButton,
  memberEmailsAdd,
  memberEmailsMark,
  saveUndoStyle,
  saveButtonStyle,
  undoButtonStyle,
  disabledButtonStyle,
  disabledSaveButtonStyle,
  editButtonStyle,
} = USER_SETTINGS;

export const themeSettingLSKey = 'theme-setting';

const ShowHideEditButton = ({ mode, setMode }: ButtonProps) => {
  if (mode === Mode.edit || mode === Mode.create) {
    return (null);
  }
  return (
    <a
      role="button"
      onClick={() => setMode(Mode.edit)}
      style={editButtonStyle}
      tabIndex={0}
    >
      Edit
    </a>
  );
};

const UserSettings = () => {
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const userSettings = useSelector<GlobalState>((state) => state.login.settings) as UserSettingType;
  const isAdmin = isSystemAdmin(user);
  const userId = user.id;

  const [userConfig, setUserConfig] = useState<UserSettingType['config']>(userSettings.config);
  const [screenTheme, setScreenTheme] = useState<string>(_.get(window.localStorage, buildKey(userId, themeSettingLSKey)));

  // MemberEmailType consists of objects and due its reference nature, setMemberEmailSelection will not always result in a rerender of the parent
  const [memberEmails, setMemberEmails] = useState<MemberEmailType>([]);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>(Mode.view);
  const dispatch = useDispatch();

  const emailFrequency = userConfig.emailFrequency;
  const cronExp = _.split(emailFrequency, ' ');
  const time = momentTimezone(`${cronExp[1]}:${cronExp[0]}}`, 'H:mm a').format('H:mm A');
  const timeZone = userConfig.timeZone;

  const methods = useForm({ mode: 'onChange' });
  const { control, getValues } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'memberEmails',
    shouldUnregister: false,
  });

  const memberEmailsForm = useWatch({ name: 'memberEmails', control });
  const membersWithSharedNotifications = _.map(memberEmailsForm, 'member.id');
  const [members, memberFetchError] = useMemberFetcher(user);
  const options = getOptionsWithDspIcon(_.filter(members, (m) => !_.includes(membersWithSharedNotifications, m.id)));

  const disabled = mode === Mode.view;

  useMount(() => {
    const myKey = buildKey(userId, themeSettingLSKey);
    const currentClass = window.localStorage.getItem(myKey);
    setScreenTheme(currentClass);
  });

  useEffect(() => {
    if (!members) return;
    if (_.size(userConfig.sharedNotifications) > 0) {
      const memberIds = _.keys(userConfig.sharedNotifications).map((key) => +key);
      const selectedMembers = _.filter(members, (o) => memberIds.includes(o.id));
      const memberEmailsFromDB = [];
      _.forEach(userConfig.sharedNotifications, (value, key) => {
        const mem = _.find(selectedMembers, (o) => o.id === +key);
        if (mem) {
          memberEmailsFromDB.push({ member: mem, emails: value, invalidEmails: new Set<string>() });
        }
      });
      const orderedMemberEmailsFromDB = _.orderBy(memberEmailsFromDB, (o) => o.member.displayName.toLowerCase(), ['asc']);
      append(orderedMemberEmailsFromDB);
      setMemberEmails(orderedMemberEmailsFromDB);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, userConfig.sharedNotifications]);

  const lastMemberEmail: MemberEmail = _.last(memberEmailsForm);
  const sharedNotificationsError = (!disabled && lastMemberEmail && (!lastMemberEmail?.member || !lastMemberEmail?.emails.length));
  const addButtonDisabled = disabled || sharedNotificationsError || emailError;

  const saveUserSettings = (key: string, value: string | boolean) => {
    const mergedConfig = _.merge(userConfig, { [key]: value });
    setUserConfig({ ...mergedConfig });
  };

  const updateSettings = () => {
    const memberNotificationEmails = {};
    // eslint-disable-next-line array-callback-return
    getValues().memberEmails.map((i) => {
      if (i.member && i.emails.length) {
        memberNotificationEmails[_.toString(i.member.id)] = i.emails;
      }
    });
    updateUserSettings(_.toString(userSettings.id), { ...userConfig, ...{ sharedNotifications: memberNotificationEmails } }, dispatch);

    // Theme
    const myKey = buildKey(userId, themeSettingLSKey);
    window.localStorage.setItem(myKey, screenTheme);
    document.documentElement.className = screenTheme;

    setMode(Mode.view);
  };

  const changeTheme = (theme: string | void) => {
    if (theme) {
      document.documentElement.className = theme;
    } else {
      const newTheme = screenTheme ? '' : 'dark';
      setScreenTheme(newTheme);
    }
  };

  const onCancel = () => {
    // TODO: too many complicated states built in - will address in re-write
    // easiest way to reset the form is to reload the page
    window.location.reload();
  };

  return (
    <PermissionPageTemplate
      title={PAGE_NAME}
      name={PAGE_NAME}
        // @ts-ignore - page template
      subheader={PAGE_NAME}
      subNavElements={<ShowHideEditButton mode={mode} setMode={setMode} />}
    >
      <FormProvider {...methods}>
        <Grid stackable celled="internally" style={baseTable}>
          <Grid.Row columns={1} style={baseRow}>
            <Grid.Column width={12} style={baseColumn}>
              <EmailNotifications
                time={time}
                timeZoneSetting={timeZone}
                saveUserSetting={saveUserSettings}
                disabled={disabled}
              />
            </Grid.Column>
          </Grid.Row>
          {isAdmin && (
            <Grid.Row columns={1} style={baseRow}>
              <Grid.Column width={12} style={baseColumn}>
                <DisplayTheme
                  screenTheme={screenTheme}
                  changeTheme={changeTheme}
                  disabled={disabled}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1} style={baseRow}>
            <Grid.Column width={12} style={baseColumn}>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={3} style={sectionHeader}>
                      Share Notifications
                      <Popup
                        hoverable
                        basic
                        wide="very"
                        position="right center"
                        trigger={(
                          <span>
                            <img
                              style={{ margin: '0 6px 0 10px' }}
                              src="/img/icons/16x16/infocircle.svg"
                              width="16px"
                              alt="check"
                            />
                          </span>
                        )}
                      >
                        <p>
                          The notifications that are emailed to you can also be emailed to your colleagues.
                          <a href={SHARED_NOTIFICATION_LINK} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>Read More.</a>
                        </p>
                      </Popup>
                    </td>
                  </tr>
                  {!!fields.length && (
                    fields.map((_item, index) => (
                      <MemberEmails
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        index={index}
                        options={options}
                        memberEmails={memberEmails}
                        setMemberEmails={setMemberEmails}
                        setEmailError={setEmailError}
                        remove={remove}
                        disabled={!!memberFetchError || disabled}
                      />
                    ))
                  )}
                  <tr>
                    <td colSpan={3} style={sectionHeader}>
                      <div>
                        <button
                          type="button"
                          style={addButtonDisabled ? { ...memberEmailsButton, ...disabledButtonStyle } : memberEmailsButton}
                          disabled={addButtonDisabled}
                          onClick={() => {
                            append({ member: null, emails: null, invalidEmails: new Set<string>() });
                            setMemberEmails(getValues('memberEmails'));
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={addButtonDisabled ? { ...memberEmailsMark, ...disabledButtonStyle } : memberEmailsMark}>+</span>
                            <span style={addButtonDisabled ? { ...memberEmailsAdd, ...disabledButtonStyle } : memberEmailsAdd}>Add</span>
                          </div>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr style={lineItemRow} />
                </tbody>
              </table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} style={baseRow}>
            <Grid.Column width={12} style={baseColumn}>
              <table width="100%">
                <tbody>
                  <tr style={{ width: '100%', height: '220px' }}>
                    <td style={saveUndoStyle}>
                      <Button
                        disabled={disabled}
                        style={disabled ? { ...undoButtonStyle, ...disabledButtonStyle } : undoButtonStyle}
                        type="button"
                        onClick={onCancel}
                        secondary
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={addButtonDisabled}
                        style={addButtonDisabled ? { ...saveButtonStyle, ...disabledSaveButtonStyle } : saveButtonStyle}
                        type="button"
                        onClick={updateSettings}
                        primary
                      >
                        Save
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FormProvider>
    </PermissionPageTemplate>
  );
};

export default UserSettings;
