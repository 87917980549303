import _ from 'lodash';
import React from 'react';
import { Card, Icon, Popup } from 'buildingBlocks';
import { StrategyType } from 'utils/types';
import strategyWizard from 'containers/StrategyWizard/styles';
import { cardHeaderStyles, cardIconStyles, cardIconSelected, cardTextStyle, cardDescriptionStyle } from 'containers/StrategyWizard/steps/StrategyTypeSelection/styles';
import { LegendDspIcons, LegendText } from './Legend';

type NewOrBetaTagProps = {
  isItemSelected: boolean
};

const BetaTag = ({ isItemSelected }: NewOrBetaTagProps) => (
  <div
    className={(isItemSelected ? 'right floated blue' : 'right floated inverted')}
    style={strategyWizard.cardImgWrapper}
  >
    <img
      src="/img/components/StrategyWizard/beta_ribbon.svg"
      style={{ position: 'relative', right: '-9px' }}
      alt="Beta"
    />
  </div>
);

const NewTag = ({ isItemSelected }: NewOrBetaTagProps) => (
  <div
    className={(isItemSelected ? 'right floated blue' : 'right floated inverted')}
    style={strategyWizard.cardImgWrapper}
  >
    <img
      src="/img/components/StrategyWizard/new_badge.svg"
      width="50px"
      alt="New Strategy Type!"
    />
  </div>
);

type StratTypeCardContentProps = {
  item: StrategyType,
  isItemSelected?: boolean,
  cardContentStyling?: {},
};

const StrategyTypeCardContent = ({ item, isItemSelected, cardContentStyling }: StratTypeCardContentProps) => (
  <Card.Content style={cardContentStyling}>
    {item.beta && <BetaTag isItemSelected={isItemSelected} />}
    {item.new && <NewTag isItemSelected={isItemSelected} />}
    <Popup
      content={(
        <span>
          <a href={_.get(item, 'helpCenterLink')} target="_blank" rel="noopener noreferrer">Learn about</a> this strategy type and how to configure it.
        </span>
      )}
      trigger={(
        <Icon
          className="right floated strategy-type-card-tooltip-icon"
          style={isItemSelected ? { ...cardIconStyles, ...cardIconSelected } : cardIconStyles}
          name="question"
          circular
        />
      )}
      hoverable
      basic
    />
    <Card.Header
      className={(isItemSelected ? 'ui blue' : 'ui')}
      content={item.displayName}
      style={cardHeaderStyles}
    />
    <Card.Meta>
      <LegendText style={cardTextStyle}>
        {item.description}
      </LegendText>
      <LegendDspIcons dspId={item.dsp} />
    </Card.Meta>
    <Card.Description style={cardDescriptionStyle} content={item.help} />
  </Card.Content>
);

type EnabledStrategyProps = {
  item: StrategyType,
  itemSelected: boolean,
  onClick: () => void,
};

export const EnabledStrategyTypeCard = ({ item, itemSelected, onClick }: EnabledStrategyProps) => (
  <Card
    onClick={onClick}
    className={itemSelected ? 'strategy-type-card-selected' : ''}
    color={itemSelected ? 'blue' : undefined}
  >
    <StrategyTypeCardContent
      item={item}
      isItemSelected={itemSelected}
    />
  </Card>
);

type DisabledStrategyProps = {
  item: StrategyType,
};

export const DisabledStrategyTypeCard = ({ item }: DisabledStrategyProps) => (
  <Card
    className="disabled"
  >
    <StrategyTypeCardContent
      item={item}
      cardContentStyling={strategyWizard.disabledCardStyle}
    />
  </Card>
);
