import _ from 'lodash';
import React from 'react';
import { Header } from 'buildingBlocks';
import { STRATEGY_TYPE } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { StrategyType } from 'utils/types';
import { STRATEGY_CONFIGURATION_STYLES } from '../styles';

export const ModuleHeader = ({ displayName, id }: StrategyType) => {
  const stratType = STRATEGY_TYPE.getById(id as number);
  return (
    <>
      <Header
        as="h4"
        style={STRATEGY_CONFIGURATION_STYLES.header}
        content={displayName}
        className={`strategy-type-${id}`}
      />
      <p style={STRATEGY_CONFIGURATION_STYLES.subHeader}>
        {_.get(stratType, 'description')}
        <span> For more information on configurable options please see the Copilot
          <a href={_.get(stratType, 'helpCenterLink')} style={{ color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE }} target="_blank" rel="noopener noreferrer"> Help Center</a>.
        </span>
      </p>
    </>
  );
};

export default ModuleHeader;
