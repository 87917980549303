import React from 'react';
import Toggle from 'components/Toggle';
import { USER_SETTINGS } from '../style';

const {
  sectionHeader,
  lineItemHeader,
  lineItemRow,
} = USER_SETTINGS;

type DisplayThemeProps = {
  screenTheme: string
  changeTheme: (theme: string | void) => void
  disabled: boolean
};

const DisplayTheme = (props: DisplayThemeProps) => {
  const { screenTheme, changeTheme, disabled } = props;
  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={2} style={sectionHeader}>
            Theme
          </td>
        </tr>
        <tr>
          <td style={lineItemHeader}>
            Dark mode
          </td>
          <td>
            <Toggle
              checked={screenTheme === 'dark'}
              onChange={() => changeTheme()}
              disabled={disabled}
            />
          </td>
        </tr>
        <tr style={lineItemRow} />
      </tbody>
    </table>
  );
};
export default DisplayTheme;
