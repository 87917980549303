/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid } from 'buildingBlocks';
import { SegmentGroupsType } from 'utils/types';
import Goal from './Goal';

type HeliosSegmentRecencyConfirmDetailsProps = {
  strategyConfig: {
    segmentGroups: SegmentGroupsType
  }
  goal: {}
  currencyCode: string
  viewability: {
    enabled: boolean
    target?: number
  }
};

export const HeliosSegmentRecencyConfirmDetails = ({
  goal,
  viewability,
}: HeliosSegmentRecencyConfirmDetailsProps) => (
  <div>
    <Grid style={{ margin: '16px 0 0' }}>
      <Grid.Row style={{ margin: 0, padding: 0 }}>
        <Goal {...goal} viewability={viewability} />
      </Grid.Row>
    </Grid>
  </div>
);

export default HeliosSegmentRecencyConfirmDetails;
