import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Grid, Header, Message } from 'buildingBlocks';
import { SELECT_STRATEGY_TYPE, WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import { StrategyType as StrategyTypeProp } from 'utils/types';
import {
  containerStyles,
  rowStyles,
  columnStyles,
  headerStyles,
  subHeaderStyles,
  stratTypeContainerStyles,
} from 'containers/StrategyWizard/steps/StrategyTypeSelection/styles';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { GlobalState } from 'reducers';
import { WizardFormValues } from 'containers/StrategyWizard/types';
import { updateQueryString } from 'containers/StrategyWizard/StrategyWizard';
import { QSParams } from 'containers/StrategyWizard/hooks';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import StrategyTypeRadio from './components/StrategyTypeRadio';
import Legend from './components/Legend';

export type StrategyTypeStatus = {
  unsupportedAdTypes: Array<string>,
  unsupportedFlightTypes: Array<number>,
  noFlightSupported: boolean,
  multipleFlightTypesSupported: boolean,
};

type Props = {
  selectedStrategyType: StrategyTypeProp
  loading: boolean
  disabled: boolean
  dspId: number
  setSelectedStrategyType: (x: StrategyTypeProp) => void
  dispatch: Dispatch<SetStateAction<any>>
  qsParams: QSParams
  defaultValue?: StrategyTypeProp
  strategyTypesToDisable?: Array<StrategyTypeStatus>
  items?: Array<StrategyTypeProp>
  error?: string
};

const StrategyType = ({
  selectedStrategyType,
  disabled,
  items,
  error,
  loading,
  dspId,
  strategyTypesToDisable,
  defaultValue,
  setSelectedStrategyType,
  dispatch,
  qsParams,
}: Props) => {
  const reduxWizardFormValues = useSelector<GlobalState>((rootState) => rootState.strategyWizard) as WizardFormValues;
  const {
    strategyTypeSelectionStep: { strategyType: reduxStrategyType },
    strategyConfigurationStep: reduxConfigurationValues,
  } = reduxWizardFormValues;
  const navigate = useNavigate();
  const prevFormValues = usePrevious(reduxWizardFormValues);
  const formMethods = useForm({ defaultValues: { strategyType: defaultValue } });
  const { reset, control } = formMethods;

  useMount(() => {
    reset({ strategyType: defaultValue });
  });

  const onNextClick = () => {
    const resetConfigValues = _.get(reduxStrategyType, 'id') !== _.get(selectedStrategyType, 'id');
    dispatch({ type: SELECT_STRATEGY_TYPE, payload: { strategyType: selectedStrategyType } });
    const values = {
      ...reduxWizardFormValues,
      strategyTypeSelectionStep: { strategyType: selectedStrategyType },
      strategyConfigurationStep: resetConfigValues ? {} : reduxConfigurationValues,
    } as WizardFormValues;
    const pathname = `/strategies/wizard/${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`;
    updateQueryString(values, qsParams, navigate, prevFormValues, pathname);
  };

  return (
    <Form
      loading={loading}
      error={!!error}
    >
      <Grid celled="internally" style={containerStyles}>
        <Grid.Row columns={1} style={rowStyles}>
          {!!error && (
            <Message
              style={{ margin: '0 auto' }}
              error
              header="Failed to load strategy types"
              content={error}
            />
          )}
          <Grid.Column
            style={columnStyles}
          >
            <Header as="h4" style={headerStyles}>Strategy Type</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} style={rowStyles}>
          <Header as="h4" style={subHeaderStyles}>Select a strategy type.</Header>
        </Grid.Row>
        <Grid.Row columns={1} style={rowStyles}>
          <Grid.Column style={stratTypeContainerStyles}>
            <Controller
              name="strategyType"
              control={control}
              defaultValue={selectedStrategyType}
              render={() => (
                <StrategyTypeRadio
                  items={items}
                  onChange={setSelectedStrategyType}
                  value={selectedStrategyType}
                  strategyTypesToDisable={strategyTypesToDisable}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column style={{ padding: 0, marginTop: 24 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                { /*
              // @ts-ignore return jsx array that ts doesnt like */}
                <Legend dspId={dspId} />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <NavFooter
        backButtonId={WIZARD_STEPS[WizardSteps.goalSelectionStep].id}
        onNextClick={onNextClick}
        nextButtonDisabled={disabled}
      />
    </Form>
  );
};

export default StrategyType;
