import React from 'react';
import { Form, Grid, Divider } from 'buildingBlocks';
import { StrategyType, User, Member, Brand } from 'utils/types';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { canManageBrands, canManageFlights } from 'containers/StrategyWizard/utils';
import ModuleHeader from './Header';

const { column, row, divider } = strategyWizardStyles;

type Props = {
  onSubmit?: () => void
  strategyType: StrategyType
  strategyId: number
  user: User
  member: Member
  hasBudgetAllocationError: boolean
  brand?: Brand
  disabled?: boolean
  loading?: boolean
  children?: React.ReactNode
};

const ModuleForm = (props: Props) => {
  const { onSubmit, strategyType, strategyId, loading, user, member, brand, disabled, hasBudgetAllocationError } = props;
  const showBackButton = (member && canManageFlights(user, member)) || (brand && canManageBrands(user));

  return (
    <Form
      noValidate
      loading={loading}
    >
      <Grid celled="internally" style={{ minHeight: '229px' }}>
        <Grid.Row columns={1}>
          <Grid.Column style={column}>
            <ModuleHeader {...strategyType} />
          </Grid.Column>
        </Grid.Row>
        {hasBudgetAllocationError ? null : (<Divider style={divider} />)}
        <Grid.Row columns={1} style={row}>
          <Grid.Column style={{ padding: 'initial' }}>
            {props.children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <NavFooter
        backButtonText="Back"
        backButtonId={strategyId ? WIZARD_STEPS[WizardSteps.goalSelectionStep].id : WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id}
        showBackButton={showBackButton}
        nextButtonText={strategyId ? 'Update' : 'Next'}
        nextButtonDisabled={disabled}
        onNextClick={onSubmit}
        strategyId={strategyId}
      />
    </Form>
  );
};

export default ModuleForm;
