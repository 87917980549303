import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import qs from 'qs';
import { GlobalState } from 'reducers';
import { KoalaBoxData, StrategyConfigurationStep as StrategyConfigurationStepFormType, WizardFormValues } from 'containers/StrategyWizard/types';
import { useInitialDataFetcher, FormLoadingState, WizardMode, QSParams } from 'containers/StrategyWizard/hooks';
import { CONFIGURE_STRATEGY, RESET_NEXT_BTN_CLICK, SET_NEXT_BTN_CLICK, WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import { getStepUrl, isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { STRATEGY_TYPE } from 'constantsBase';
import { createLinkWithQS } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { User, StrategyConfig, StrategyType } from 'utils/types';
import KoalaBox from './components/KoalaBox';
import StrategyConfigurationForm from './components/StrategyConfigurationForm';
import { getUpdatedGroupSettingsWithClones, isKoalaBoxEnabled } from './utils';
import Loader from './components/Loader';
import { getValidatorPerStrategyType } from './validate';
import { isBudgetOptimization } from '../StrategyConfirmation/utils';
import { RevenueTypeOutcomeOptions } from '../GoalSelection/constants';

type StrategyConfigurationStepProps = {
  strategyConfig: StrategyConfig
  qsParams: QSParams
  strategyId?: number
  strategyTypeId?: number
};

const getMode = (id: number, moduleFormValues: StrategyConfigurationStepFormType) => {
  if (id && !_.isNil(moduleFormValues)) {
    return WizardMode.existingStrategy;
  }
  // remove fromSmartAttach fields in case the user creates the strategy through satellite
  if (!_.isEmpty(_.omit(moduleFormValues, ['fromSmartAttach']))) {
    return WizardMode.formPreviouslyFilled;
  }
  return WizardMode.newStrategy;
};

const StrategyConfigurationStep = (props: StrategyConfigurationStepProps) => {
  const { strategyConfig, strategyId, strategyTypeId, qsParams } = props;
  const dispatch = useDispatch();
  const {
    attachFlightsStep: { member, brand, advertiser, attachedFlights, defaultCurrency },
    goalSelectionStep,
    strategyTypeSelectionStep: { strategyType },
    strategyConfigurationStep,
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const navigate = useNavigate();
  const goalType = _.get(goalSelectionStep, 'goal.type');
  const revenueOutcomeType = _.get(goalSelectionStep, 'revenueOutcomeType');
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const mode = getMode(strategyId, strategyConfigurationStep);

  const stratType = strategyType ?? STRATEGY_TYPE.getById(_.toNumber(_.get(qsParams, 'wizardForm.strategyType')));
  const hasMultiRevType = _.isEqual(revenueOutcomeType, RevenueTypeOutcomeOptions.multiple);
  // @ts-ignore
  const loadingState = useInitialDataFetcher(
    mode,
    member,
    advertiser,
    attachedFlights,
    stratType as StrategyType,
    strategyConfig,
    strategyConfigurationStep,
    _.get(defaultCurrency, 'code'),
    goalType,
    _.get(qsParams, 'populateSegments', false),
    hasMultiRevType,
    goalSelectionStep,
  );
  const [koalaBoxOpen, setKoalaBoxOpen] = useState(false);
  const [koalaBoxValues, setKoalaBoxValues] = useState<KoalaBoxData>({ maxBid: null, unboundedComputeNodes: null });
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const nextLink = createLinkWithQS(getStepUrl(strategyId, WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id));

  // intelligentChildObjects must always be false if the strategy has multiple rev types or values
  if (hasMultiRevType) {
    _.set(strategyConfigurationStep, 'intelligentChildObjects', false);
  }

  const initialValues = strategyId ? strategyConfigurationStep : _.get(loadingState, 'data');
  const formMethods = useForm<StrategyConfigurationStepFormType>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: getValidatorPerStrategyType(strategyTypeId ?? _.get(stratType, 'id')),
  });

  const { reset, formState: { errors }, getValues } = formMethods;
  const isCrossPlatform = isCrossPlatformStrategyType(stratType?.id);
  const isBudgetOptimizationStrategy = isBudgetOptimization(strategyType?.id) || isCrossPlatform;
  const hasBudgetAllocationError = (isBudgetOptimizationStrategy && budgetAllocationState.kind === Status.error);

  useMount(() => {
    dispatch({ type: RESET_NEXT_BTN_CLICK });
  });

  useEffect(() => {
    reset(initialValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const updateQueryString = (values: StrategyConfigurationStepFormType) => {
    // omit some values from being set in the url because they cause very long urls and chrome has a 64k char limit
    const filteredFormValues = _.omit(values, ['segmentGroups', 'segments', 'customTreeInput', 'treeFileInput', 'groupSettings']);
    if (!strategyId) {
      navigate(`?${qs.stringify({ ...qsParams, moduleForm: filteredFormValues })}`, { replace: true });
    }
  };

  const hasKoalaBox = isKoalaBoxEnabled(_.get(strategyType, 'id'));
  // redirect after the form has been submitted successfully
  if (shouldRedirect) {
    return <Navigate to={nextLink} />;
  }

  const goToConfirmStep = () => {
    let strategyConfiguration = getValues();
    const groupSettings = _.get(strategyConfiguration, 'groupSettings');

    if (!_.isEmpty(groupSettings) && _.get(strategyConfiguration, 'intelligentChildObjects')) {
      // add intelligent line item extId if it exists in origToClone mapping
      const updatedGroupSettings = getUpdatedGroupSettingsWithClones(budgetAllocationState, groupSettings);
      strategyConfiguration = { ...strategyConfiguration, groupSettings: updatedGroupSettings };
    }
    dispatch({ type: SET_NEXT_BTN_CLICK, payload: true });
    dispatch({ type: CONFIGURE_STRATEGY, payload: strategyConfiguration });
    // omit prevSetIntelChildObj for QS - only used to track if user touched INT toggle
    updateQueryString(_.omit(strategyConfiguration, 'prevSetIntelChildObj') as StrategyConfigurationStepFormType);
    setShouldRedirect(true);
  };

  const handleSubmit = () => {
    if (hasKoalaBox && _.get(koalaBoxValues, 'maxBid') !== null) {
      setKoalaBoxOpen(hasKoalaBox);
    } else {
      goToConfirmStep();
    }
  };

  switch (loadingState.kind) {
    case FormLoadingState.hasData: {
      return (
        <>
          <StrategyConfigurationForm
            strategyType={strategyType}
            strategyId={strategyId}
            user={user}
            member={member}
            hasBudgetAllocationError={hasBudgetAllocationError}
            disabled={!_.isEmpty(errors) || hasBudgetAllocationError}
            onSubmit={handleSubmit}
          >
            {
              // when editing, attachedFlights is fetched separately than the strategy since attachedFlights is a
              // separate store. need to make sure they exist for modules that make use of the flights.
              attachedFlights && ( // strategyConfigurationStep &&
                <FormProvider {...formMethods}>
                  <Loader
                    strategyTypeId={_.get(strategyType, 'id')}
                    initialValues={initialValues}
                    strategyId={strategyId}
                    setShouldRedirect={setShouldRedirect}
                    setKoalaBoxValues={setKoalaBoxValues}
                    goToConfirmStep={goToConfirmStep}
                  />
                </FormProvider>
              )
            }
          </StrategyConfigurationForm>
          {
              hasKoalaBox && (
                <KoalaBox
                  koalaBoxData={koalaBoxValues}
                  toggleModal={(prev: boolean) => setKoalaBoxOpen(!prev)}
                  modalOpen={koalaBoxOpen}
                  onClick={goToConfirmStep}
                />
              )
            }
        </>
      );
    }

    case FormLoadingState.initial:
    case FormLoadingState.loading:
    default:
      return (
        <StrategyConfigurationForm
          strategyType={strategyType}
          strategyId={strategyId}
          user={user}
          member={member}
          hasBudgetAllocationError={hasBudgetAllocationError}
          brand={brand}
          loading
        />
      );
  }
};

export default StrategyConfigurationStep;
