import _ from 'lodash';
import React, { ReactElement } from 'react';
import { WppEmptyError } from '@wppopen/components-library-react';
import DocumentTitle from 'react-document-title';
import { Container } from 'buildingBlocks';
import { titleStyle, msgStyle, centerAuto, unknownErrorMsgStyle } from 'containers/403/styles';

type Props = {
  message?: string | ReactElement
  customFooter?: ReactElement
};

const UnknownErrorPage = ({ message, customFooter }: Props) => (
  <>
    <DocumentTitle title="Access Denied" />
    <Container
      fluid
      textAlign="center"
    >
      <div style={centerAuto}>
        <WppEmptyError />
        <h1 style={titleStyle}>Unable to Load Strategy</h1>
        <div style={{ ...msgStyle, ...unknownErrorMsgStyle }}>
          { message }
        </div>
        {!_.isNil(customFooter) && customFooter}
      </div>
    </Container>
  </>
);

export default UnknownErrorPage;
