import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { Grid, List, SemanticWIDTHS } from 'buildingBlocks';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { configuringCrossPlatformStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { Advertiser, Member, Flight, OptimizationLevelType, Brand, FlightDisplayName } from 'utils/types';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';

type BasicStratListProps = {
  width: SemanticWIDTHS
  first: string
  second?: string
  third?: string
  fourth?: string
  fifth?: string
  style?: CSSProperties
};

export const BasicStratInfoList = ({ width, style, first, second, third, fourth, fifth }: BasicStratListProps) => (
  <Grid.Column width={width} style={{ ...style, ...STRATEGY_CONFIRMATION_STYLES.listStyle }}>
    <List>
      <List.Item style={{ ...STRATEGY_CONFIRMATION_STYLES.individualTextLine, marginTop: 0 }}>
        <List.Content>{first}</List.Content>
      </List.Item>
      <List.Item style={STRATEGY_CONFIRMATION_STYLES.individualTextLine}>
        <List.Content>{second}</List.Content>
      </List.Item>
      {third && (
        <List.Item style={STRATEGY_CONFIRMATION_STYLES.individualTextLine}>
          <List.Content>{third}</List.Content>
        </List.Item>
      )}
      {fourth && (
        <List.Item style={STRATEGY_CONFIRMATION_STYLES.individualTextLine}>
          <List.Content>{fourth}</List.Content>
        </List.Item>
      )}
      {fifth && (
        <List.Item style={STRATEGY_CONFIRMATION_STYLES.individualTextLine}>
          <List.Content>{fifth}</List.Content>
        </List.Item>
      )}
    </List>
  </Grid.Column>
);

type BasicStratInfoProps = {
  optimizationLevel: FlightDisplayName
  attachedFlights: Array<Flight>
  strategyTypeDisplayName: string
  selectedOptType: OptimizationType
  member?: string
  advertiser?: string
  brand?:string
};

const renderBasicStratInfo = ({ member, advertiser, optimizationLevel, brand, attachedFlights, strategyTypeDisplayName, selectedOptType }: BasicStratInfoProps) => {
  const multipleFlightsAttached = _.size(attachedFlights) > 1;
  const dspSpecificOptLevel = multipleFlightsAttached ? `Multiple ${optimizationLevel.multiple}` : optimizationLevel.single;
  const optLevel = configuringCrossPlatformStratCheck(selectedOptType) ? 'Cross-Platform' : dspSpecificOptLevel;

  if (advertiser && !brand) {
    return (
      <>
        <BasicStratInfoList width={3} first="Member" second="Advertiser" third="Strategy Type" fourth="Optimization Level" style={STRATEGY_CONFIRMATION_STYLES.subHeader} />
        <BasicStratInfoList width={9} first={member} second={advertiser} third={strategyTypeDisplayName} fourth={optLevel} style={STRATEGY_CONFIRMATION_STYLES.mainText} />
      </>
    );
  }
  if (!advertiser && brand) {
    return (
      <>
        <BasicStratInfoList width={3} first="Brand" second="Strategy Type" third="Optimization Level" style={STRATEGY_CONFIRMATION_STYLES.subHeader} />
        <BasicStratInfoList width={9} first={brand} second={strategyTypeDisplayName} third={optLevel} style={STRATEGY_CONFIRMATION_STYLES.mainText} />
      </>
    );
  }
  return (
    <>
      <BasicStratInfoList width={3} first="Brand" second="Member" third="Advertiser" fourth="Strategy Type" fifth="Optimization Level" style={STRATEGY_CONFIRMATION_STYLES.subHeader} />
      <BasicStratInfoList width={9} first={brand} second={member} third={advertiser} fourth={strategyTypeDisplayName} fifth={optLevel} style={STRATEGY_CONFIRMATION_STYLES.mainText} />
    </>
  );
};

const BasicStratInfo = ({ member, advertiser, optimizationLevel, brand, attachedFlights, strategyTypeDisplayName, selectedOptType }: BasicStratInfoProps) => (
  <Grid style={{ margin: '16px 0 0' }}>
    <Grid.Row style={{ margin: 0, padding: 0 }}>
      {renderBasicStratInfo({ member, advertiser, optimizationLevel, brand, attachedFlights, strategyTypeDisplayName, selectedOptType })}
    </Grid.Row>
  </Grid>
);

type ConfirmHeaderProps = {
  attachedFlights: Array<Flight>
  advertiser: Advertiser
  member: Member
  optimizationLevel: OptimizationLevelType
  strategyTypeDisplayName: string
  selectedOptType: OptimizationType
  brand?: Brand
};

const ConfirmHeader = ({
  advertiser,
  member,
  optimizationLevel,
  attachedFlights,
  brand,
  strategyTypeDisplayName,
  selectedOptType,
}: ConfirmHeaderProps) => (
  <div>
    <BasicStratInfo
      member={_.get(member, 'displayName')}
      advertiser={_.get(advertiser, 'name')}
      optimizationLevel={_.get(optimizationLevel, 'displayName')}
      brand={_.get(brand, 'name')}
      attachedFlights={attachedFlights}
      strategyTypeDisplayName={strategyTypeDisplayName}
      selectedOptType={selectedOptType}
    />
  </div>
);

export default ConfirmHeader;
