import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { COPILOT_COLORS } from 'globalStyles';
import { MemberEmailType } from 'utils/types';
import { USER_SETTINGS } from '../style';
import { EmailChip } from './EmailChip';

const MAX_EMAIL_LIMIT = 10;

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS },
} = COPILOT_COLORS;

const {
  emailInput,
  errorMessageStyle,
  enterEmails,
} = USER_SETTINGS;

type EmailChipsProps = {
  index: number
  items: Array<string>
  onChange: (...event: any[]) => void
  disabled: boolean
  setEmailError: Dispatch<SetStateAction<boolean>>
  memberEmails: MemberEmailType
  setMemberEmails: Dispatch<SetStateAction<MemberEmailType>>
};

const EmailChips = (props: EmailChipsProps) => {
  const { index, items, onChange, disabled, setEmailError, memberEmails, setMemberEmails } = props;
  const [emailList, setEmailList] = useState<Array<string>>([]);
  const [inputValue, setInputValue] = useState('');
  const errors = memberEmails[index].invalidEmails;
  const errorMessage = useRef('');

  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    if (items) {
      setEmailList(items);
    }
  }, [index, items]);

  const isInList = (email) => emailList.includes(email);
  // eslint-disable-next-line no-useless-escape
  const isEmail = (email) => /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email);
  const isValid = (email) => {
    if (isInList(email)) {
      errorMessage.current = `${email} has already been added.`;
      return false;
    }
    if (!isEmail(email) && !errors.has(email)) {
      errors.add(email);
      setValue(`memberEmails[${index}].invalidEmails`, errors);
      return false;
    }
    return true;
  };

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      if (_.isEmpty(inputValue)) return;
      evt.preventDefault();
      const textEntered = evt.target.value.trim();
      if (!isValid(textEntered) && !isInList(textEntered)) {
        errors.add(textEntered);
        setValue(`memberEmails[${index}].invalidEmails`, errors);
      }
      if (emailList.length === MAX_EMAIL_LIMIT) {
        setInputValue('');
        errorMessage.current = 'You have reached the limit of 10 email addresses.';
      }
      if (textEntered && !isInList(textEntered) && emailList.length < 10) {
        setEmailList([...emailList, textEntered]);
        setInputValue('');
        errorMessage.current = '';
        setMemberEmails(getValues('memberEmails'));
      }
      onChange(emailList);
    }
  };

  const handleChange = (evt) => {
    setInputValue(evt.target.value);
  };

  const handleDelete = (item) => {
    setEmailList([...emailList.filter((i) => i !== item)]);
    errors.delete(item);
    errorMessage.current = '';
    setMemberEmails(getValues('memberEmails'));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    // eslint-disable-next-line no-useless-escape
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      const toBeAdded = emails.filter((email) => !isInList(email));
      setEmailList([...emailList, ...toBeAdded]);
    }
  };

  // This makes the latest emailList available to getValues()
  useEffect(() => {
    onChange(emailList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailList]);

  // Check with each render
  useEffect(() => {
    const error = (_.some(memberEmails, (i) => i.invalidEmails.size) || _.some(memberEmails, (i) => i.emails?.length && i?.member === null));
    setEmailError(error);
  });

  return (
    <>
      <div style={!disabled ? emailInput : { ...emailInput, backgroundColor: NEUTRALS.N100_CLOUD }}>
        {emailList.map((item, i) => (
          <EmailChip
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            disabled={disabled}
            errors={errors}
            item={item}
            handleDelete={handleDelete}
          />
        ))}
        <input
          value={inputValue}
          placeholder={!disabled ? 'Enter Emails' : null}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          style={!disabled ? enterEmails : { ...enterEmails, backgroundColor: 'transparent' }}
          disabled={disabled}
        />
      </div>
      {errorMessage.current
        && (
        <span style={errorMessageStyle}>
          <img
            style={{ margin: '0 6px 0 0' }}
            src="/img/icons/16x16/triangle-exclamation.svg"
            width="16px"
            alt="check"
          />
          {errorMessage.current}
        </span>
        )}
    </>
  );
};
export default EmailChips;
