import _ from 'lodash';
import React from 'react';
import numeral from 'numeral';
import { Grid, Header, Icon, Table } from 'buildingBlocks';
import { MetricsFormattingConstants } from 'constantsBase';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { BudgetSetting } from 'containers/StrategyWizard/types';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { formatNumber } from 'utils/formattingUtils';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import { displayDescription } from '../utils';
import InfoCircleIcon from './InfoCircleIcon';

const { column } = strategyWizardStyles;

type Props = {
  goToStep: () => void,
  budgetSettings: Array<BudgetSetting>
  defaultCurrency: string
  flightExtType: number
  flightTimezone: string
};

const BudgetIntervals = ({
  goToStep,
  budgetSettings,
  defaultCurrency,
  flightExtType,
  flightTimezone,
}: Props) => {
  // if there was an issue getting the budgetSettings an empty array would be returned
  const failedToGetBudgetSettings = _.isEmpty(budgetSettings);

  return (
    <Grid.Row>
      <Grid.Column style={column}>
        <Header
          as="h4"
          style={STRATEGY_CONFIRMATION_STYLES.header}
        >
          Budget Intervals
          <span style={STRATEGY_CONFIRMATION_STYLES.icon}>
            <Icon name="edit" size="small" link onClick={goToStep} />
          </span>
        </Header>
        {failedToGetBudgetSettings
          ? (
            <p style={STRATEGY_CONFIRMATION_STYLES.errorMsg}>Error fetching Budget Intervals</p>
          ) : (
            <>
              <div style={STRATEGY_CONFIRMATION_STYLES.step5Table}>
                <InfoCircleIcon />
                {displayDescription(budgetSettings.length, 'Interval')}
              </div>
              <Table style={{ width: '420px' }}>
                <Table.Header>
                  <Table.Row key={column.value}>
                    <Table.HeaderCell style={STRATEGY_CONFIRMATION_STYLES.date}>
                      Start Date
                    </Table.HeaderCell>
                    <Table.HeaderCell style={STRATEGY_CONFIRMATION_STYLES.date}>
                      End Date
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Budget
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(budgetSettings, ({ startDate, endDate, budget, type }: BudgetSetting, i: number) => {
                    const budgetTypeInImps = _.isEqual(type, BudgetTypes.imps);
                    const budgetLabel = budgetTypeInImps ? _.upperCase(BudgetTypes.imps) : defaultCurrency;
                    const intervalBudget = budgetTypeInImps ? formatNumber(budget) : numeral(budget).format(MetricsFormattingConstants.TWO_DECIMALS);
                    return (
                      <Table.Row key={i + 1}>
                        <Table.Cell>{displayDate(startDate, flightExtType, flightTimezone)}</Table.Cell>
                        <Table.Cell>{displayDate(endDate, flightExtType, flightTimezone)}</Table.Cell>
                        <Table.Cell>{`${intervalBudget} ${budgetLabel}`}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </>
          )}
      </Grid.Column>
    </Grid.Row>
  );
};

export default BudgetIntervals;
