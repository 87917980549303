import React from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import CustomTreeKoalaBoxContent, { CUSTOM_TREE_MODAL_HEADER } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomTree/components/ChickenBox';
import { Button } from 'buildingBlocks';
import { KoalaBoxData } from 'containers/StrategyWizard/types';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

type Props = {
  koalaBoxData: KoalaBoxData
  toggleModal: (prev: boolean) => void
  modalOpen: boolean
  onClick: () => void
};

const ModuleKoalaBox = ({ koalaBoxData, toggleModal, modalOpen, onClick }: Props) => (
  <ConfirmationModal
    hideDefaultBtns
    open={modalOpen}
    size="mini"
    dimmer="blurring"
    modelName={CUSTOM_TREE_MODAL_HEADER}
    message={(<CustomTreeKoalaBoxContent {...koalaBoxData} />)}
    customBtns={(
      <div>
        <Button
          content="No"
          onClick={() => toggleModal(modalOpen)}
          style={strategyWizardStyles.backButtonStyle}
        />
        <Button
          primary
          type="submit"
          content="Yes"
          onClick={onClick}
          style={strategyWizardStyles.nextButtonStyle}
        />
      </div>
)}
  />
);

export default ModuleKoalaBox;
