import _ from 'lodash';
import { SetStateAction } from 'react';
import { Dispatch } from 'redux';
import { STRATEGY_TYPE, BOOLEAN_OPERATORS } from 'constantsBase';
import { getInitialValues as getHeliosInitialValues } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/utils';
import { getInitialValues as getSRNInitialValues } from 'containers/StrategyWizard/ConfigurationByStrategyType/SegmentRecency/utils';
import { mapConfigFromDbToUi } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { INITIAL_VALUES as CUSTOM_TREE_INITIAL_VALUES } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomTree/constants';
import { INITIAL_VALUES as CUSTOM_BID_LIST_INITIAL_VALUES } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomBidList/constants';
import {
  INITIAL_VALUES as BASE_BUDGET_OPTIMIZATION_INITIAL_VALUES,
  BUDGET_OPTIMIZATION_ALGO_TYPES,
  CHILD_PRIORITIES,
  CROSS_PLATFORM_INITIAL_VALUES,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { INITIAL_VALUES as DBM_CUSTOM_SDF_INITIAL_VALUES } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomSDF/constants';
import { StrategyConfigurationStep, WizardFormGoalSelection, WizardFormValuesForInitialization } from 'containers/StrategyWizard/types';
import { CONFIGURE_STRATEGY, SELECT_GOALS } from 'containers/StrategyWizard/constants';
import { BudgetAllocationResponse, GroupSettings } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import BetterSet from 'utils/betterSet';
import { AppNexus } from 'utils/copilotAPI';
import { CurrencyExchange, Flight, Member } from 'utils/types';

export const isKoalaBoxEnabled = (stratTypeId: number) => _.get(STRATEGY_TYPE.getById(stratTypeId), 'chickenBox');

export const getBoolOperatorForSegments = (boolStr: string) => {
  if (boolStr === 'or') {
    return BOOLEAN_OPERATORS.OR;
  }
  return BOOLEAN_OPERATORS.AND;
};

export const getProfileExtIdsFromFlights = (flightCandidates: Array<Flight>) => {
  const additionalInfo = _.map(flightCandidates, (fc) => JSON.parse(_.get(fc, 'additionalInfo')));
  return _.compact(_.map(additionalInfo, 'profileExtId'));
};

export const getSegmentGroupsForFlights = async (
  flightCandidates: Array<Flight>,
  member: Member,
  profileExtIds?: Array<string>,
) => {
  let profiles = profileExtIds;
  try {
    if (_.isNil(profileExtIds)) {
      profiles = getProfileExtIdsFromFlights(flightCandidates);
    }
    if (!_.isEmpty(profiles)) {
      const apnProfiles = await AppNexus.profile(profiles, member.id.toString());
      const segmentGroupTargets = _.compact(_.map(apnProfiles.data.response, 'segment_group_targets'));
      return segmentGroupTargets;
    }
  } catch (e) {
    console.log('an error occured while getting profile id - ', e);
  }
  return [];
};

export const getSegmentGroupTargetsData = async (wizardForm: WizardFormValuesForInitialization, populateSegments?: boolean) => {
  if (populateSegments) {
    return getSegmentGroupsForFlights(wizardForm.attachedFlights, wizardForm.member);
  }
  return [];
};

export const getInitialValuesByStrategyType = async (
  strategyTypeId: number,
  wizardFormValues?: WizardFormValuesForInitialization,
  currencyExchange?: CurrencyExchange | {},
  stratDefaultWizardConfig?: { maxBid?: number },
  populateSegments?: boolean,
) => {
  switch (strategyTypeId) {
    case STRATEGY_TYPE.helios.id:
      return getHeliosInitialValues(stratDefaultWizardConfig, wizardFormValues, currencyExchange, populateSegments);
    case STRATEGY_TYPE.heliosSegmentRecency.id:
      return getSRNInitialValues(stratDefaultWizardConfig, wizardFormValues, currencyExchange, populateSegments);
    case STRATEGY_TYPE.customTree.id:
      return CUSTOM_TREE_INITIAL_VALUES;
    case STRATEGY_TYPE.customBidList.id:
      return CUSTOM_BID_LIST_INITIAL_VALUES;
    case STRATEGY_TYPE.amznBudgetOptimization.id:
    case STRATEGY_TYPE.apnBudgetOptimization.id:
    case STRATEGY_TYPE.ttdBudgetOptimization.id:
    case STRATEGY_TYPE.dbmBudgetOptimization.id:
    case STRATEGY_TYPE.wmtBudgetOptimization.id:
      return BASE_BUDGET_OPTIMIZATION_INITIAL_VALUES;
    case STRATEGY_TYPE.crossPlatformOptimization.id:
      return CROSS_PLATFORM_INITIAL_VALUES;
    case STRATEGY_TYPE.dbmCustomSDF.id:
      return DBM_CUSTOM_SDF_INITIAL_VALUES;
    default:
      return null;
  }
};

const getNumericKeys = (obj: { [key: string]: any }) => _.filter(_.keys(obj), (k) => !isNaN(Number(k)));

export const compareNumericKeys = (prev: { [key: string]: any }, next: { [key: string]: any }) => {
  const prevKeys = new BetterSet(getNumericKeys(prev));
  const nextKeys = new BetterSet(getNumericKeys(next));
  const removes = prevKeys.difference(nextKeys);
  const adds = nextKeys.difference(prevKeys);

  // if there's no difference in the keys, overwrite the values with the new ones
  if (removes.union(adds).size === 0) {
    return { ...prev, ...next };
  }

  const newObj = _.omit(next, Array.from(removes));
  return newObj;
};

const ignorePassthroughKeys = (formValues, config) => {
  const updatedConfigKeys = _.keys(config);
  if (
    _.some(updatedConfigKeys, (k) => _.includes(BUDGET_OPTIMIZATION_ALGO_TYPES, parseInt(k, 10)))
    && !_.includes(updatedConfigKeys, CHILD_PRIORITIES)
  ) {
    return _.omit(formValues, CHILD_PRIORITIES);
  }
  return formValues;
};

export const updateFormWithAdminBoxValues = (
  values: string,
  strategyTypeId: number,
  formValues: StrategyConfigurationStep,
  goalSelectionStep: WizardFormGoalSelection,
  dispatch: Dispatch<SetStateAction<any>>,
  dspId: number,
) => {
  const parsed = JSON.parse(values);
  const config = mapConfigFromDbToUi(parsed, strategyTypeId, dspId);
  const updatedFormValues = ignorePassthroughKeys(formValues, config);
  const updatedObj = compareNumericKeys(updatedFormValues, config);
  const adminConfigParentToClientRevenueValue = _.get(updatedObj, 'parentToClientRevenueValue');
  if (_.has(updatedObj, 'clientEventRevenueValue') || _.has(updatedObj, 'budgetSettings')) {
    const updatedClientEventRevenueValue = _.get(updatedObj, 'clientEventRevenueValue');
    const updatedBudgetSettings = _.get(updatedObj, 'budgetSettings');
    const updatedGoalValues = {
      ...goalSelectionStep,
      ...(updatedClientEventRevenueValue && { budget: { ...goalSelectionStep.budget, clientEventRevenueValue: updatedClientEventRevenueValue } }),
      ...(updatedBudgetSettings && { budgetSettings: updatedBudgetSettings }),
    };
    dispatch({ type: SELECT_GOALS, payload: updatedGoalValues });
  }
  dispatch({ type: CONFIGURE_STRATEGY, payload: (adminConfigParentToClientRevenueValue ? { ...updatedObj, adminConfigParentToClientRevenueValue } : updatedObj) });
};

const getCloneExtIds = (childExtIds: Array<string>, origToClone: { [key: string]: string }) => _.uniq(_.reduce(childExtIds, (acc, lineItemExtId) => {
  if (_.has(origToClone, lineItemExtId)) {
    acc.push(lineItemExtId, origToClone[lineItemExtId]);
  } else {
    acc.push(lineItemExtId);
  }
  return acc;
}, []));

export const getUpdatedGroupSettingsWithClones = (
  budgetAllocationData: BudgetAllocationResponse,
  groupSettings: GroupSettings,
) => {
  const allOrigToCloneMappings = _.assign({}, ..._.map(_.get(budgetAllocationData, 'data'), 'origToClone'));
  const updatedGroupSettings = _.reduce(groupSettings, (acc, budgetGroup, groupId: string) => {
    const updatedChildExtIds = getCloneExtIds(budgetGroup.childExtIds, allOrigToCloneMappings);
    acc[groupId] = { ...budgetGroup, childExtIds: updatedChildExtIds };
    return acc;
  }, {});
  return updatedGroupSettings;
};
