import React from 'react';
import { IMG_ICONS_16_BASE_PATH } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { USER_SETTINGS } from '../style';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, REDS },
} = COPILOT_COLORS;

const {
  emailItem,
  emailDelete,
  checkCircleDisabledStyle,
} = USER_SETTINGS;

type EmailChipProps = {
  disabled: boolean
  errors: Set<string>
  item: string
  handleDelete: (item: any) => void
};

export const EmailChip = (props: EmailChipProps) => {
  const { disabled, errors, item, handleDelete } = props;

  const valid = !errors.has(item);
  const validStyle = valid ? { backgroundColor: NEUTRALS.N50_ALABASTER } : { backgroundColor: REDS.R50_VISAGE };
  const emailStyle = { ...emailItem, ...validStyle };
  const imgStyle = !disabled ? { margin: '0 4px 0 4px' } : { margin: '0 4px 0 4px', ...checkCircleDisabledStyle };

  return (
    <div style={!disabled ? emailStyle : { ...emailStyle, color: NEUTRALS.N400_GRANITE }} key={item}>
      <img
        style={imgStyle}
        src={valid ? `${IMG_ICONS_16_BASE_PATH}/circle-check.svg` : `${IMG_ICONS_16_BASE_PATH}/red-circle-x.svg`}
        width="16px"
        alt="check"
      />
      {item}
      <button
        type="button"
        style={emailDelete}
        disabled={disabled}
        onClick={() => handleDelete(item)}
      >
        <img
          src={`${IMG_ICONS_16_BASE_PATH}/X.svg`}
          width="16px"
          alt="check"
        />
      </button>
    </div>
  );
};
