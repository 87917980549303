import _ from 'lodash';
import React from 'react';
import { Dropdown } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { USER_SETTINGS } from '../style';
import { tzNames, timeZoneDisplayNameFormat } from '../utils';

const {
  sectionHeader,
  lineItemHeader,
  lineItemRow,
  timeContainer,
  frequencyTime,
  notifyMeInputs,
} = USER_SETTINGS;

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

type TimeZoneProp = {
  key: string
  text: string
  value: string
};

type TimeZoneDropdownProps = {
  timeZone: string
  items: Array<TimeZoneProp>,
  saveUserSetting: (key: string, value: string | boolean) => void,
  disabled: boolean
};

const TimeZoneDropdown = (props: TimeZoneDropdownProps) => {
  const { disabled, timeZone, items, saveUserSetting } = props;

  return (
    <Dropdown
      style={!disabled ? notifyMeInputs : { ...notifyMeInputs, backgroundColor: NEUTRALS.N200_LINEN }}
      text={timeZoneDisplayNameFormat(timeZone)}
      title={`${timeZoneDisplayNameFormat(timeZone)} ${timeZone}`}
      onChange={(_param, data) => saveUserSetting('timeZone', _.toString(data.value))}
      search
      selection
      item
      scrolling
      direction="right"
      options={items}
      disabled={disabled}
    />
  );
};

type EmailNotificationsProps = {
  time: string
  timeZoneSetting: string
  saveUserSetting: (key: string, value: string | boolean) => void
  disabled: boolean
};

const EmailNotifications = (props: EmailNotificationsProps) => {
  const { time, timeZoneSetting, saveUserSetting, disabled } = props;
  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={2} style={sectionHeader}>
            Email Notifications
          </td>
        </tr>
        <tr>
          <td style={lineItemHeader}>
            Time
          </td>
          <td>
            <div style={timeContainer}>
              <p style={frequencyTime}>{time}</p>
              <TimeZoneDropdown
                timeZone={timeZoneSetting}
                items={tzNames}
                saveUserSetting={saveUserSetting}
                disabled={disabled}
              />
            </div>
          </td>
        </tr>
        <tr style={lineItemRow} />
      </tbody>
    </table>
  );
};

export default EmailNotifications;
