import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { GOAL_TYPES, MetricsFormattingConstants } from 'constantsBase';
import { RevenueTypeConfig, WizardFormValues, FormulaType } from 'containers/StrategyWizard/types';
import { getRevenueTypePluralizer, isAWGGoalType, isCYODGoalTypeValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { RevenueTypeOutcomeOptions } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { getSingleRevTypeAndValue } from 'containers/StrategyWizard/utils';
import { formatGoal, formatNumber } from 'utils/formattingUtils';
import { BasicStratInfoList } from './ConfirmHeader';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import { revenueGoalStratInfo } from '../utils';

type GoalT = {
  type?: string
  target?: number | string
  currencyCode?: string
  hasCustomRevenueType?: boolean
  lifetimeBudget?: number
  viewability: {
    enabled: boolean
    target?: number
  }
  budget?: RevenueTypeConfig
  customGoal?: Partial<FormulaType>
};

const getTypeTarget = (type, target, currencyCode, customGoal, hasMultiOutcomeOrValues) => {
  const isAwgGoalType = isAWGGoalType(type);
  if (type) {
    return {
      goalType: isAwgGoalType ? _.startCase(customGoal?.name) : GOAL_TYPES[type].text,
      goalTarget: formatGoal(GOAL_TYPES[type], target, currencyCode, undefined, hasMultiOutcomeOrValues, false),
    };
  }
  return { goalType: '', goalTarget: '' };
};

const Goal = ({ type, target, currencyCode, hasCustomRevenueType, lifetimeBudget, viewability, budget, customGoal }: GoalT) => {
  const { goalSelectionStep } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const hasMultiOutcomeOrValues = _.get(goalSelectionStep, 'revenueOutcomeType') === RevenueTypeOutcomeOptions.multiple;
  const { goalType, goalTarget } = getTypeTarget(type, target, currencyCode, customGoal, hasMultiOutcomeOrValues);
  const goalText = hasCustomRevenueType ? 'Revenue' : 'Goal';
  const showLifetimeBudget = hasCustomRevenueType && lifetimeBudget;
  const { revenueType, clientEventRevenueValue } = getSingleRevTypeAndValue(budget);
  const hasCustomGoal = !!_.get(goalSelectionStep, 'customGoal');

  return (
    <>
      {showLifetimeBudget && (
        <>
          <BasicStratInfoList
            width={3}
            first="Lifetime Budget"
            style={STRATEGY_CONFIRMATION_STYLES.subHeader}
          />
          <BasicStratInfoList
            width={9}
            first={`${formatNumber(lifetimeBudget)} ${getRevenueTypePluralizer(_.toLower(goalType))(target)}`}
            style={STRATEGY_CONFIRMATION_STYLES.mainText}
          />
        </>
      )}
      {revenueType && (
        <>
          <BasicStratInfoList
            width={3}
            first="Revenue Type"
            style={STRATEGY_CONFIRMATION_STYLES.subHeader}
          />
          <BasicStratInfoList
            width={9}
            first={!hasMultiOutcomeOrValues ? revenueGoalStratInfo(clientEventRevenueValue, goalType, currencyCode, true, false, revenueType, hasCustomGoal) : _.capitalize(RevenueTypeOutcomeOptions.multiple)}
            style={STRATEGY_CONFIRMATION_STYLES.mainText}
          />
        </>
      )}
      {hasCustomRevenueType ? (
        <>
          <BasicStratInfoList
            width={3}
            first={`${goalText} Value`}
            style={STRATEGY_CONFIRMATION_STYLES.subHeader}
          />
          <BasicStratInfoList
            width={9}
            first={revenueGoalStratInfo(target, goalType, currencyCode)}
            style={STRATEGY_CONFIRMATION_STYLES.mainText}
          />
        </>
      ) : (
        <>
          <BasicStratInfoList
            width={3}
            first={`${goalText} Target`}
            style={STRATEGY_CONFIRMATION_STYLES.subHeader}
          />
          <BasicStratInfoList
            width={9}
            first={revenueGoalStratInfo(_.endsWith(_.toString(goalTarget), '%') ? goalTarget : target, goalType, currencyCode, false, isCYODGoalTypeValue(type))}
            style={STRATEGY_CONFIRMATION_STYLES.mainText}
          />
        </>
      )}
      {viewability.enabled && (
        <>
          <BasicStratInfoList
            width={3}
            first="Viewability"
            style={STRATEGY_CONFIRMATION_STYLES.subHeader}
          />
          <BasicStratInfoList
            width={9}
            first={numeral(viewability.target).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}
            style={STRATEGY_CONFIRMATION_STYLES.mainText}
          />
        </>
      )}
    </>
  );
};
export default Goal;
