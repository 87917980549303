import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Member, MemberEmailType, PrettyOption } from 'utils/types';
import { ObjectDropdown, Image } from 'buildingBlocks';
import { ALL } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { DSP } from 'constantsBase';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { COPILOT_COLORS } from 'globalStyles';
import { USER_SETTINGS } from '../style';
import EmailChips from './EmailChips';

const { NEW_DESIGN_SYSTEM: { NEUTRALS: { N200_LINEN } } } = COPILOT_COLORS;
const REMOVE_ICON = '/img/icons/16x16/x-icon.svg';
const REMOVE_ICON_FOR_DISABLE = '/img/icons/16x16/x-icon-disable.svg';

const {
  memberStyle,
  emailsCellStyle,
  memberDropdownStyle,
  memberEmailsButton,
  disabledButtonStyle,
  memberShareWithTitleStyle,
  memberEmailsAdd,
  removeIconStyle,
  removeButtonStyle,
} = USER_SETTINGS;

type MemberEmailsProps = {
  index: number
  options: Array<PrettyOption>
  disabled: boolean
  memberEmails: MemberEmailType
  setMemberEmails: Dispatch<SetStateAction<MemberEmailType>>
  setEmailError: Dispatch<SetStateAction<boolean>>
  remove: (index?: number | number[]) => void
};

const MemberEmails = (props: MemberEmailsProps) => {
  const {
    index,
    options,
    disabled,
    memberEmails,
    setMemberEmails,
    setEmailError,
    remove,
  } = props;

  const { control, getValues, setValue } = useFormContext();
  const displayText = 'Search Members...';
  const memberDropdownStyles = !disabled ? { ...memberDropdownStyle, backgroundColor: 'transparent' } : { ...memberDropdownStyle, backgroundColor: N200_LINEN };
  const removeBtn = !disabled ? memberEmailsButton : { ...memberEmailsButton, ...disabledButtonStyle };

  const handleMemberSelect = (i: number, member: Member) => {
    if (_.find(memberEmails, ['member.id', member.id])) {
      return;
    }
    setValue(`memberEmails[${i}].member`, member);
    setMemberEmails(getValues('memberEmails'));
  };

  const removeMemberEmails = () => {
    remove(index);
    setMemberEmails(getValues('memberEmails'));
  };

  return (
    <>
      <tr>
        <td style={memberShareWithTitleStyle}>
          Member
        </td>
        <td style={memberShareWithTitleStyle}>
          Share with
        </td>
      </tr>
      <tr>
        <td style={memberStyle}>
          <Controller
            name={`memberEmails[${index}].member`}
            control={control}
            rules={{ required: true }}
            render={() => (
              <ObjectDropdown
                fluid
                selection
                options={options}
                onChange={(member: Member) => handleMemberSelect(index, member)}
                keyFn={(mem) => (_.isEqual(mem.displayName, ALL) ? mem.displayName : `${DSP.getById(_.get(mem, 'dsp') as number).code} - ${mem.displayName}`)}
                selectOnBlur={false}
                disabled={disabled}
                text={memberEmails[index].member ? memberEmails[index].member.displayName : displayText}
                placeholder={memberEmails[index].member ? null : 'Search Members...'}
                search={{
                  searchType: 'local',
                  onSearchChange: searchByNameOrExtId(),
                }}
                style={memberDropdownStyles}
              />
            )}
          />
        </td>
        <td style={emailsCellStyle}>
          <Controller
            name={`memberEmails[${index}].emails`}
            control={control}
            rules={{ required: true }}
            render={(emailChipsProps) => (
              <EmailChips
                index={index}
                items={memberEmails[index].emails}
                onChange={emailChipsProps.field.onChange}
                memberEmails={memberEmails}
                setMemberEmails={setMemberEmails}
                disabled={disabled}
                setEmailError={setEmailError}
                {...emailChipsProps}
              />
            )}
          />
        </td>
        <td>
          <div>
            <button
              type="button"
              disabled={disabled}
              style={removeBtn}
              onClick={removeMemberEmails}
            >
              <div style={removeButtonStyle}>
                <span>
                  <Image
                    style={removeIconStyle}
                    src={disabled ? REMOVE_ICON_FOR_DISABLE : REMOVE_ICON}
                  />
                </span>
                <span style={disabled ? { ...memberEmailsAdd, ...disabledButtonStyle } : memberEmailsAdd}>Remove</span>
              </div>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default MemberEmails;
