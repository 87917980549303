import { CSSProperties } from 'react';
import { color } from 'd3';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY, COPILOT_COLORS } from 'globalStyles';

const {
  grey,
  NEW_DESIGN_SYSTEM: { NEUTRALS, METTLES, REDS, YELLOWS },
} = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

const borderColor = color(METTLES.M900_TROUT);
borderColor.opacity = 0.15;

const strategyWizard: { [key: string]: CSSProperties | any } = {
  container: {
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  base: {
    backgroundColor: NEUTRALS.N0_WHITE,
    border: `1px solid ${borderColor}`,
    borderOpacity: 0.15,
  },
  a: {
    border: 'initial',
    padding: '5px',
    boxShadow: 'initial',
    color: 'inherit',
    background: 'initial',
    fontWeight: 'initial',
    margin: 'initial',
  },
  column: {
    padding: SPACING[24],
  },
  row: {
    boxShadow: 'none',
  },
  divider: {
    margin: '0px 24px',
  },
  flight: {
    ERROR: {
      color: REDS.R700_VALENTINE,
    },
    WARNING: {
      color: YELLOWS.Y500_GOLD,
    },
    default: {
      whiteSpace: 'pre',
    },
    table: {
      marginTop: SPACING[16],
    },
    accordion: {
      marginTop: '40px',
    },
    accordionTable: {
      borderSpacing: '8px',
    },
  },
  navButtonContainerStyle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: grey,
    padding: 0,
    paddingTop: SPACING[16],
    marginBottom: SPACING[32],
    width: '100%',
  },
  backArrowStyle: {
    color: NEUTRALS.N800_MATTER,
    fontSize: 16,
  },
  backButtonStyle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: NEUTRALS.N800_MATTER,
    padding: `${SPACING[4]}px ${SPACING[48]}px ${SPACING[4]}px ${SPACING[8]}px`,
    boxShadow: 'none',
    border: 'none',
  },
  nextButtonStyle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    textTransform: 'capitalize',
    color: NEUTRALS.N0_WHITE,
    padding: `${SPACING[4]}px ${SPACING[32]}px`,
  },
  toBeDetachedModalStyle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    float: 'right',
    borderRadius: 4,
    backgroundColor: NEUTRALS.N0_WHITE,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    color: NEUTRALS.N800_MATTER,
    padding: '5px 24.5px',
    textTransform: 'none',
  },
  flightRowIconContainer: {
    width: '25px',
    position: 'relative',
  },
  flightRowIcon: {
    fontSize: 7,
    position: 'absolute',
  },
  modalFlightName: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    fontWeight: 500,
    width: '100%',
    wordBreak: 'break-all',
  },
  cancelPopupActionContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
  },
  progressTitle: {
    cursor: 'default',
  },
};

export default strategyWizard;
